// src/App.js
import ReactDOM from 'react-dom';
import React, { Component } from 'react'
import Slider from 'react-slick'
import { Link, BrowserRouter as Router } from 'react-router-dom'

import './App.css'

// Components
import Container3d from './Components/Container3d'
import FirstStep from './Components/FirstStep'
import SecondStep from './Components/SecondStep'
import ThirdStep from './Components/ThirdStep'
import FourthStep from './Components/FourthStep'

class App extends Component {

  leatherPrices = {
    'Smooth-patterned':	3.20,
    'Grainy-patterned': 2.20,
    'Suede-patterned': 0.20,
    'Woven-patterned': 0
  }

  constructor(props) {
    super(props)

    var icons = ['┤','╣','║','╗','╝','┐','└','┴','┬','├','─','┼','╚','╔','╩','╦','╠','═','╬','┘','┌','█','▄','▀','■','░','▒','▓','²','⌂','³','¹','¶','•','○','◙','§','▬','↨','↑','↓','→','←','∟','↔','▲','▼','ø','½','Ø','®','¬','«','»','│','æ','Æ','î','ò','►','◄','↕','é','ä','à','å','ë','ï','Ä','ê','â','è','Å','É','ô','û','ù','£','ê'];
		var names = ['Flower 1','Flower 2','Flower 3','Star 1','Star 2','Star 3','Star 4','Star 5','Star 6','Star 7','Diamond','Ring','Cloud 1','Cloud 2','Moon','Lightining 1','Lightining 2','Snowflake 1','Snowflake 2','Clover 1','Clover 2','Rainbow','Anchor','Boat','Cat','Flamingo','Bird','Butterfly 1','Butterfly 2','Dog','Whale','Bear','Paw 1','Paw 2','Smile 1','Smile 2','Hamburger','Pizza','Icecream','Crown','Heart 1','Heart 2','Heart 3','Heart 4','Heart 5','Heart 6','XO','Love 1','Love 2','Love 3','Love 4','XOXO','Withallmylove','Party','Rad','Congrats','Taurus 1','Gemini 1','Cancer 1','Leo 1','Virgo 1','Libra 1','Scorpio 1','Sagittarius 1','Capricorn 1','Aquarius 1','Pisces 1','Aries 2','Taurus 2','Gemini 2','Cancer 2','Leo 2','Libra 2','Virgo 2','Scorpio 2','Sagittarius 2','Capricorn 2','Aquarius 2','Pisces 2'];

    this.state = {
      activeCurrency: "EUR",
      activeSymbol: "€",
      activeKur:1,
      eurKur: 1,
      tryKur: 0,
      gbpKur: 0,
      bagName: '',
      totalPrice: 0,
      newPrice:0,
      initialPrice: null,
      areaOneLeatherType: "Smooth-patterned",
      areaTwoLeatherType: "Smooth-patterned",
      areaThreeLeatherType: "Smooth-patterned",
      areaFourLeatherType: "Smooth-patterned",
      areaFiveKolonType: "Woven-patterned",
      interiorLining: 'null',
      colorOne: null,
      colorTwo: null,
      colorThree: null,
      colorFour: null,
      colorFive: null,
      monogramText: null,
      monogramColor: "white",
      metalElement: null,
      productId: null,
      variationId: null,
      primaryLeatherPrice: 0,
      secondryLeatherPrice: 0,
      primaryBeltLeatherPrice: 0,
      secondaryBeltLeatherPrice: 0,
      kolonTexturePrice: 0,
      index: 1,
      refreshKey: 0,
      icons: icons,
			names : names
    }

    this.handleNameChange = this.handleNameChange.bind(this)
    this.stepLeft = this.stepLeft.bind(this)
    this.stepRight = this.stepRight.bind(this)
    this.handleAddPrimaryPrice = this.handleAddPrimaryPrice.bind(this)
    this.handleAddSecondryPrice = this.handleAddSecondryPrice.bind(this)
    this.handleAddPrimaryBeltPrice = this.handleAddPrimaryBeltPrice.bind(this)
    this.handleAddSecondaryBeltPrice = this.handleAddSecondaryBeltPrice.bind(this)
    this.handleAddKolonTexturePrice = this.handleAddKolonTexturePrice.bind(this)
    this.changeTextureOne = this.changeTextureOne.bind(this)
    this.changeTextureTwo = this.changeTextureTwo.bind(this)
    this.changeBeltOne = this.changeBeltOne.bind(this)
    this.changeBeltTwo = this.changeBeltTwo.bind(this)
    this.changeKolon = this.changeKolon.bind(this)
    this.changeInterior = this.changeInterior.bind(this)
    this.changeText = this.changeText.bind(this)
    this.changeTextColor = this.changeTextColor.bind(this)
    this.changeMetal = this.changeMetal.bind(this)
  }

  handleNameChange(bagName, bagPrice, bagId,varId,textureType1,texture1,textureType2,texture2,textureType3,texture3,textureType4,texture4,metal,lining) {
    console.log('start handleNameChange');
    ReactDOM.findDOMNode(this.refs['bags_trundler']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_verti-trundler']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_macro-pucker']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_micro-pucker']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_midi-styler']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_mini-styler']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_urban-collar']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_urban-leash']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_chain-leash']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_dreamer']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_urban-case']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_longi']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_maxi-dualer']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_midi-dualer']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_mini-dualer']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_dual-leash']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_dual-chain-leash']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_dual-collar']).classList.remove('selected-bag');
    ReactDOM.findDOMNode(this.refs['bags_dual-harness']).classList.remove('selected-bag');


    let route_url;
    switch (bagName) {
      case 'Chain Leash':
        route_url = 'chain-leash';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Urban Leash':
        route_url = 'urban-leash';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Urban Collar':
        route_url = 'urban-collar';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Mini Styler':
        route_url = 'mini-styler';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Midi Styler':
        route_url = 'midi-styler';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Micro Pucker':
        route_url = 'micro-pucker';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Macro Pucker':
        route_url = 'macro-pucker';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Verti Trundler':
        route_url = 'verti-trundler';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
      case 'Trundler':
        route_url = 'trundler';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Dreamer':
        route_url = 'dreamer';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Urban Case':
        route_url = 'urban-case';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Longi':
        route_url = 'longi';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Maxi Dualer':
        route_url = 'maxi-dualer';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Midi Dualer':
        route_url = 'midi-dualer';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Mini Dualer':
        route_url = 'mini-dualer';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Dual Leash':
        route_url = 'dual-leash';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Dual Chain Leash':
        route_url = 'dual-chain-leash';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Dual Collar':
        route_url = 'dual-collar';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;
       case 'Dual Harness':
        route_url = 'dual-harness';
        ReactDOM.findDOMNode(this.refs['bags_'+route_url]).classList.add('selected-bag');
        break;

    }
    //genel modeller ilk yükleme config
    this.setState((prevState) => ({
      routeUrl: route_url,
      productId: bagId,
      variationId: varId,
      bagName,
      initialPrice: bagPrice,
      newPrice:bagPrice,
      primaryLeatherPrice: 0,
      secondryLeatherPrice: 0,
      primaryBeltLeatherPrice: 0,
      secondaryBeltLeatherPrice: 0,
      kolonTexturePrice: 0,
      index: 1,
      refreshKey: prevState.refreshKey + 1
    }))

    //konsept modeller ilk yüklenme config
    var bagsCustom = ['Maxi Dualer Fuchsia','Midi Dualer Lavatera','Mini Dualer Sunset','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isCustomModel = bagsCustom.includes(bagName)
    if(isCustomModel){
      this.setState((prevState) => ({
        routeUrl: route_url,
        productId: bagId,
        variationId: varId,
        bagName,
        initialPrice: bagPrice,
        primaryLeatherPrice: 0,
        secondryLeatherPrice: 0,
        primaryBeltLeatherPrice: 0,
        secondaryBeltLeatherPrice: 0,
        kolonTexturePrice: 0,
        index: 4,
        refreshKey: prevState.refreshKey + 1,
        areaOneLeatherType: textureType1,
        areaTwoLeatherType: textureType2,
        areaThreeLeatherType: textureType3,
        areaFourLeatherType: textureType4,
        interiorLining: lining,
        metalElement: metal,
        colorOne: texture1,
        colorTwo: texture2,
        colorThree: texture3,
        colorFour: texture4,
      }))
    }

  }

  stepRight(e) {
    //todo 3 step olanlar astar derisi olmayanlar
		var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isStep = bags.includes(this.state.bagName)
    if (this.state.index !== 3 && isStep ) {
      this.setState((prevState) => ({
        index: prevState.index + 1
      }))
    } else if (this.state.index !== 4 && !isStep ) {
      this.setState((prevState) => ({
        index: prevState.index + 1
      }))
    }
  }

  stepLeft(e) {
    if (this.state.index !== 1) {
      this.setState((prevState) => ({
        index: prevState.index - 1
      }))
    }
  }

  handleAddPrimaryPrice(leatherType) {
    let decimeterSquare, areaOneLeatherType;
    switch (this.state.bagName) {
      case 'Trundler':
        decimeterSquare = 20;
        break;
      case 'Verti Trundler':
        decimeterSquare = 30;
        break;
      case 'Macro Pucker':
        decimeterSquare = 40;
        break;
      case 'Micro Pucker':
        decimeterSquare = 15;
        break;
      case 'Midi Styler':
        decimeterSquare = 55;
        break;
      case 'Mini Styler':
        decimeterSquare = 35;
        break;
      case 'Chain Leash':
        decimeterSquare = 2;
        break;
      case 'Urban Leash':
        decimeterSquare = 5;
        break;
      case 'Urban Collar':
        decimeterSquare = 5;
        break;
      case 'Dreamer':
          decimeterSquare = 40;
        break;
      case 'Urban Case':
          decimeterSquare = 30;
        break;
      case 'Dual Leash':
          decimeterSquare = 4;
        break;
      case 'Dual Harness':
        decimeterSquare = 10;
        break;
      case 'Dual Harness Candy (M Size)':
        decimeterSquare = 10;
        break;
      case 'Dual Harness Sky (M Size)':
        decimeterSquare = 10;
        break;
      case 'Dual Collar':
        decimeterSquare = 1;
        break;
      case 'Longi':
          decimeterSquare = 42;
        break;
      case 'Maxi Dualer':
          decimeterSquare = 25;
        break;
      case 'Maxi Dualer Fuchsia':
          decimeterSquare = 25;
        break;
      case 'Midi Dualer':
          decimeterSquare = 14;
        break;
      case 'Midi Dualer Lavatera':
          decimeterSquare = 14;
        break;
      case 'Mini Dualer':
          decimeterSquare = 8.5;
        break;
      case 'Mini Dualer Sunset':
          decimeterSquare = 8.5;
        break;
    }


      let dashIndex = leatherType.indexOf("-");
      areaOneLeatherType = leatherType.substring(0, dashIndex).toLowerCase();

    this.setState({
      primaryLeatherPrice: this.leatherPrices[leatherType] * decimeterSquare,
      areaOneLeatherType
    })
   switch (this.state.activeCurrency) {
      case "EUR":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "TRY":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "GBP":
        this.changeCurrency(this.state.activeCurrency)
        break;
      default:
        console.log('primary change switch case default')
        //this.changeCurrency(this.state.activeCurrency)
        break;
    }
  }

  handleAddSecondryPrice(leatherType) {
    let decimeterSquare, areaTwoLeatherType;
    switch (this.state.bagName) {
      case 'Trundler':
        decimeterSquare = 30;
        break;
      case 'Verti Trundler':
        decimeterSquare = 40;
        break;
      case 'Macro Pucker':
        decimeterSquare = 30;
        break;
      case 'Micro Pucker':
        decimeterSquare = 5;
        break;
      case 'Midi Styler':
        decimeterSquare = 7.5;
        break;
      case 'Mini Styler':
        decimeterSquare = 5;
        break;
      case 'Chain Leash':
        decimeterSquare = 3;
        break;
      case 'Urban Leash':
        decimeterSquare = 3;
        break;
      case 'Urban Collar':
        decimeterSquare = 5;
        break;
       case 'Dreamer':
          decimeterSquare = 25;
        break;
      case 'Urban Case':
          decimeterSquare = 15;
        break;
      case 'Dual Harness':
        decimeterSquare = 28;
        break;
      case 'Dual Harness Candy (M Size)':
        decimeterSquare = 28;
        break;
      case 'Dual Harness Sky (M Size)':
        decimeterSquare = 28;
        break;
      case 'Maxi Dualer':
          decimeterSquare = 20;
        break;
      case 'Maxi Dualer Fuchsia':
          decimeterSquare = 20;
        break;
      case 'Midi Dualer':
          decimeterSquare = 13;
        break;
      case 'Midi Dualer Lavatera':
          decimeterSquare = 13;
        break;
      case 'Mini Dualer':
          decimeterSquare = 6.5;
        break;
      case 'Mini Dualer Sunset':
          decimeterSquare = 6.5;
        break;
      case 'Longi':
          decimeterSquare = 7;
        break;
    }


      let dashIndex = leatherType.indexOf("-");
      areaTwoLeatherType = leatherType.substring(0, dashIndex).toLowerCase();

    this.setState({
      secondryLeatherPrice: this.leatherPrices[leatherType] * decimeterSquare,
      areaTwoLeatherType
    })
    switch (this.state.activeCurrency) {
      case "EUR":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "TRY":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "GBP":
        this.changeCurrency(this.state.activeCurrency)
        break;
      default:
        console.log('secondary change switch case default')
        //this.changeCurrency(this.state.activeCurrency)
        break;
    }
  }

  handleAddPrimaryBeltPrice(leatherType) {
    let decimeterSquare, areaThreeLeatherType;
    switch (this.state.bagName) {
      case 'Trundler':
        decimeterSquare = 5;
        break;
      case 'Verti Trundler':
        decimeterSquare = 5;
        break;
      case 'Macro Pucker':
        decimeterSquare = 50;
        break;
      case 'Micro Pucker':
        decimeterSquare = 30;
        break;
      case 'Midi Styler':
        decimeterSquare = 7.5;
        break;
      case 'Mini Styler':
        decimeterSquare = 10;
        break;
      case 'Urban Leash':
        decimeterSquare = 2;
        break;
      case 'Urban Collar':
        decimeterSquare = 5;
        break;
      case 'Dreamer':
          decimeterSquare = 15;
        break;
      case 'Urban Case':
          decimeterSquare = 15;
        break;
      case 'Dual Leash':
          decimeterSquare = 12;
        break;
      case 'Dual Chain Leash':
        decimeterSquare = 3;
       break;
      case 'Dual Collar':
        decimeterSquare = 4.5;
        break;
      case 'Maxi Dualer':
          decimeterSquare = 5;
        break;
      case 'Maxi Dualer Fuchsia':
          decimeterSquare = 5;
        break;
      case 'Midi Dualer':
          decimeterSquare = 9;
        break;
      case 'Midi Dualer Lavatera':
          decimeterSquare = 9;
        break;
      case 'Mini Dualer':
          decimeterSquare = 7.5;
        break;
      case 'Mini Dualer Sunset':
          decimeterSquare = 7.5;
        break;

    }

      let dashIndex = leatherType.indexOf("-");
      areaThreeLeatherType = leatherType.substring(0, dashIndex).toLowerCase();

    this.setState({
      primaryBeltLeatherPrice: this.leatherPrices[leatherType] * decimeterSquare,
      areaThreeLeatherType
    })
    switch (this.state.activeCurrency) {
      case "EUR":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "TRY":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "GBP":
        this.changeCurrency(this.state.activeCurrency)
        break;
      default:
        console.log('thirdy change switch case default')
        //this.changeCurrency(this.state.activeCurrency)
        break;
    }
  }

  handleAddSecondaryBeltPrice(leatherType) {
    let decimeterSquare, areaFourLeatherType;
    switch (this.state.bagName) {
      case 'Trundler':
        decimeterSquare = 5;
        break;
      case 'Verti Trundler':
        decimeterSquare = 5;
        break;
      case 'Midi Styler':
        decimeterSquare = 15;
        break;
      case 'Mini Styler':
        decimeterSquare = 15;
        break;
      case 'Dual Leash':
          decimeterSquare = 7;
        break;
      case 'Dual Chain Leash':
           decimeterSquare = 2.5;
        break;
      case 'Dual Collar':
        decimeterSquare = 3.5;
        break;
      case 'Mini Dualer':
          decimeterSquare = 1.5;
        break;
      case 'Mini Dualer Sunset':
          decimeterSquare = 1.5;
        break;
    }

      let dashIndex = leatherType.indexOf("-");
      areaFourLeatherType = leatherType.substring(0, dashIndex).toLowerCase();

    this.setState({
      secondaryBeltLeatherPrice: this.leatherPrices[leatherType] * decimeterSquare,
      areaFourLeatherType
    })
    switch (this.state.activeCurrency) {
      case "EUR":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "TRY":
        this.changeCurrency(this.state.activeCurrency)
        break;
      case "GBP":
        this.changeCurrency(this.state.activeCurrency)
        break;
      default:
        console.log('fourth change switch case default')
        //this.changeCurrency(this.state.activeCurrency)
        break;
    }
  }

  handleAddKolonTexturePrice(leatherType) {
    let decimeterSquare, areaFiveKolonType;
    switch (this.state.bagName) {
      case 'Longi':
        decimeterSquare = 1;
        break;
    }

      let dashIndex = leatherType.indexOf("-");
      areaFiveKolonType = leatherType.substring(0, dashIndex).toLowerCase();

    this.setState({
      kolonTexturePrice: this.leatherPrices[leatherType] * decimeterSquare,
      areaFiveKolonType
    })
  }


  handleExpandingList(e) {
    const target = e.target;
    const content = target.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  }

  changeTextureOne(e) {
    this.setState({
      colorOne: e.detail.texture
    })
  }

  changeTextureTwo(e) {
    this.setState({
      colorTwo: e.detail.texture
    })
  }

  changeBeltOne(e) {
    this.setState({
      colorThree: e.detail.texture
    })
  }

  changeBeltTwo(e) {
    this.setState({
      colorFour: e.detail.texture
    })
  }
  changeKolon(e) {
    this.setState({
      colorFive: e.detail.texture
    })
  }

  changeMetal(e) {
    this.setState({
      metalElement: e.detail.metal
    })
  }

  changeInterior(e) {
    this.setState({
      interiorLining: e.detail.interior
    })
  }

  changeText(e) {

    var icons = this.state.icons
    var names = this.state.names

    var tumkarakterlerArray = Array.from(e.detail.text);//harflerin arraye donustur
    var wordpressString = "";

    for (let index = 0; index < tumkarakterlerArray.length; index++) {

      var bulunanIndexx = icons.indexOf(tumkarakterlerArray[index]);

      if(bulunanIndexx != -1)
      {
        wordpressString = wordpressString +"(" + names[bulunanIndexx] + ")";
      }else
      {
        wordpressString = wordpressString + tumkarakterlerArray[index];
      }
    }

    this.setState({
      monogramText: wordpressString
    })

  }

  changeTextColor(e) {
    this.setState({
      monogramColor: e.detail.color
    })
  }


  changeCurrency = (e) => {

    //console.log('CHANGE CUR TOTAL PRİCE >> -- ', this.state.totalPrice)


    var selectSymbol = "";
    //var selectCurrency = e.currentTarget.getAttribute('data-currency')
    //var setPrice = e.currentTarget.getAttribute('data-price')
    var selectCurrency = e
    var setPrice= "henüz yok";

    //tikla
    switch (selectCurrency) {
      case "EUR":
          setPrice = this.state.eurKur

          selectSymbol = "€"
        break;
      case "TRY":
          setPrice = this.state.tryKur
          selectSymbol = "₺"
        break;
      case "GBP":
         setPrice = this.state.gbpKur
         selectSymbol = "£"
        break;
      default:
        //attr el ile değiştirilirse
        setPrice = '1'
        selectSymbol = '€'
        selectCurrency = 'EUR'
        break;
    }





    //console.log("changeCurrency totalPrice >>>  ",this.state.totalPrice,)

     this.setState({
     newPrice: setPrice*this.state.totalPrice,
     activeCurrency: selectCurrency,
     activeSymbol: selectSymbol,
     activeKur:  setPrice

    })


    //console.log('select currency >> -- ', selectCurrency, "active currency >> ", this.state.activeCurrency)
  }

  componentDidMount() {
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
    window.addEventListener("change-belt-two", this.changeBeltTwo);
    window.addEventListener("change-kolon", this.changeKolon);
    window.addEventListener("change-interior", this.changeInterior);
    window.addEventListener("change-text", this.changeText);
    window.addEventListener("change-text-color", this.changeTextColor);
    window.addEventListener("change-metal", this.changeMetal);

    const axios = require('axios').default;

  var bodyFormData = new FormData();
  bodyFormData.append('dovizGetir', 'ALL');

  axios({
    method: 'post',
    url: 'https://intellifi.tech/customers/api/currency/europe.php',
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
    }).then(function (response) {

        const allWithClass = Array.from(
          document.querySelectorAll('a.woocs_auto_switcher_link')
        );

        for (let i = 0; i < response.data.length; i++) {
          const data = response.data[i]['@attributes'];
            allWithClass.forEach(el => {
              var val = el.attributes['data-currency'].value
              if(val == data.currency){
                if(val == "TRY"){
                  //console.log("val try >> ", val, "/ ", data.rate)
                  this.setState({
                    tryKur: data.rate
                   })
                }
                if(val == "GBP"){
                  //console.log("val gbp >> ", val, "/ ", data.rate)
                  this.setState({
                    gbpKur: data.rate
                   })
                }
                //console.log('eur State >> ',this.state.eurKur, 'try State >> ',this.state.tryKur, 'gbp State >> ',this.state.gbpKur, )
              }

            });
        }
    }.bind(this))
    .catch(function (error) {
        console.log(error);
    });

  }

  render() {

    this.state.totalPrice = this.state.initialPrice + this.state.primaryLeatherPrice + this.state.secondryLeatherPrice + this.state.primaryBeltLeatherPrice + this.state.secondaryBeltLeatherPrice + this.state.kolonTexturePrice

    /*console.log("total price >>  ",this.state.totalPrice,
    "new price >> ", this.state.newPrice ,
    "activeCurrency >>", this.state.activeCurrency
    )*/

    if ((this.state.totalPrice * this.state.activeKur) != this.state.newPrice){
      this.changeCurrency(this.state.activeCurrency)
    }

    /*slick slider settings*/
    var settings = {
      arrows:true,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 7,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots:false,
          }
        }
      ]
    };

    let step;
    //todo 3 step olanlar astar derisi olmayanlar
    var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isStep = bags.includes(this.state.bagName)
    var bagsCustom = ['Maxi Dualer Fuchsia','Midi Dualer Lavatera','Mini Dualer Sunset','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isCustomModel = bagsCustom.includes(this.state.bagName)
    /*if(isCustomModel){
      this.state.index = 4
    }*/
    if (this.state.index === 1) {
        step =  <FirstStep
        key={this.state.refreshKey}
        onAddingPrimaryPrice={this.handleAddPrimaryPrice}
        onAddingSecondryPrice={this.handleAddSecondryPrice}
        onAddingPrimaryBeltPrice={this.handleAddPrimaryBeltPrice}
        onAddingSecondaryBeltPrice={this.handleAddSecondaryBeltPrice}
        onAddingKolonTexturePrice ={this.handleAddKolonTexturePrice}
        onStepRight={this.stepRight}
        onStepLeft={this.stepLeft}
        bagName={this.state.bagName}
      />
    } else if (this.state.index === 2) {
      step = <SecondStep onStepRight={this.stepRight} onStepLeft={this.stepLeft} bagName={this.state.bagName} />
    } else if (this.state.index === 3) {
      if (!isStep) {
        step = <ThirdStep onStepRight={this.stepRight} onStepLeft={this.stepLeft} bagName={this.state.bagName} />
      } else {
        step = <FourthStep onStepRight={this.stepRight} onStepLeft={this.stepLeft} bagName={this.state.bagName} />
      }
    } else if (this.state.index === 4) {
      step = <FourthStep onStepRight={this.stepRight} onStepLeft={this.stepLeft} bagName={this.state.bagName} />
    }

    return (
      <Router>
        <nav className="woocs_auto_switcher right" data-view="roll_blocks" aria-hidden="false">
    <ul>
            <li className="woocs_auto_bg_woocs_curr_curr">
                <a onClick={(e) => this.changeCurrency(e.currentTarget.getAttribute('data-currency'))} data-currency="EUR" className="woocs_auto_switcher_link woocs_curr_curr" >
                    <span className="woocs_add_field">€</span>
                    EUR
                </a>
            </li>

            <li className="woocs_auto_bg_">
                <a onClick={(e) => this.changeCurrency(e.currentTarget.getAttribute('data-currency'))} data-currency="TRY" className="woocs_auto_switcher_link ">
                    <span className="woocs_add_field">₺</span>
                    TRY
                </a>
            </li>

            <li className="woocs_auto_bg_">
                <a onClick={(e) => this.changeCurrency(e.currentTarget.getAttribute('data-currency'))} data-currency="GBP" className="woocs_auto_switcher_link " >
                    <span className="woocs_add_field">£</span>
                    GBP
                </a>
            </li>
    </ul>
</nav>
        <div id="product-510" classname="App" className="qode-product-with-gallery product type-product post-510 status-publish first instock product_cat-bags product_cat-challenger-bags has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
         <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2 style={{textAlign: 'center'}}>Welcome!</h2>
              <p>&nbsp;</p>
              <h4 style={{textAlign: 'center'}}><span style={{color: '#333300'}}>Happy to meet you. This page is created for you to uniquely design your Bonabag. Start with choosing a bag shape, then pick your favorite colors, add custom details and it's ready to be fully hand-crafred by our atelier. Pure artisan, pure "you". Is it a custom gift? Don't forget to add your special touch, note and gift-wrap at the final step.</span></h4>
            </div>
          </div>
          <div className="woocommerce columns-4 ">
            <ul className="products">
              <Slider {...settings}>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/longi" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/longi" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_longi">My Longi</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/maxi-dualer" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/maxi-dualer" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_maxi-dualer">My Maxi Dualer</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/midi-dualer" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/midi-dualer" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_midi-dualer">My Midi Dualer</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/mini-dualer" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/mini-dualer" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_mini-dualer">My Mini Dualer</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/dual-harness" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Harness-Cortado-L-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/dual-harness" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_dual-harness">My Dual Harness</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/dual-chain-leash" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Chain-Leash-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/dual-chain-leash" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_dual-chain-leash">My Dual Chain Leash</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/dual-leash" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Leash-Cortado-L-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/dual-leash" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_dual-leash">My Dual Leash</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/dual-collar" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Dual-Collar-Cortado-L-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/dual-collar" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_dual-collar">My Dual Collar</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/urban-case" class="product-category">
                     <span class="image-wrapper">
                       <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image" alt="" srcset="https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1.jpg 1000w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2020/01/Urban-Case-Unisex-Maxi-Vigour-1-100x100.jpg 100w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/urban-case" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_urban-case">My Urban Case</h6>
                  </Link>
              </li>
                <li class="product type-product post-1852 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/dreamer" class="product-category">
                     <span class="image-wrapper">
                      <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail wp-post-image" alt="" srcset="https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Dreamer-Unisex-Vigour-1-100x100.jpg 100w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/dreamer" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_dreamer">My Dreamer</h6>
                  </Link>
              </li>
              <li class="product type-product post-1765 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/trundler" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/trundler" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_trundler">My Trundler</h6>
                  </Link>
              </li>
              <li class="product type-product post-1846 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/verti-trundler" class="product-category">
                     <span class="image-wrapper">
                      <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Verti-Trundler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/verti-trundler" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_verti-trundler">My Verti Trundler</h6>
                  </Link>
              </li>
              <li class="product type-product post-1891 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/macro-pucker" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/macro-pucker" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_macro-pucker">My Macro Pucker</h6>
                  </Link>
              </li>
              <li class="product type-product post-1874 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/micro-pucker" class="product-category">
                     <span class="image-wrapper">
                        <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/micro-pucker" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_micro-pucker">My Micro Pucker</h6>
                  </Link>
              </li>

              <li class="product type-product post-1852 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/midi-styler" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/midi-styler" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_midi-styler">My Midi Styler</h6>
                  </Link>
              </li>
              <li class="product type-product post-1739 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable purchasable product-type-simple">
                <div class="top-product-section">
                   <Link itemprop="url" to="/mini-styler" class="product-category">
                     <span class="image-wrapper">
                     <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Mini-Styler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>
                     </span>
                  </Link>
                </div>
                   <Link itemprop="url" to="/mini-styler" class="product-category product-info">
                   <h6 itemprop="name" ref="bags_mini-styler">My Mini Styler</h6>
                  </Link>
                </li>
                 <li className="product type-product post-751 status-publish instock product_cat-mybona has-post-thumbnail taxable shipping-taxable product-type-simple">
                <div className="top-product-section">
                  <Link itemProp="url" to="/chain-leash" className="product-category">
                    <span className="image-wrapper">
                      <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Chain-Leash-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>          </span>
                  </Link>
                </div>
                <Link itemProp="url" to="/chain-leash" className="product-category product-info">
                  <h6 itemProp="name" ref="bags_chain-leash">My Chain Leash</h6>
                </Link>
                </li>
                 <li className="product type-product post-750 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable product-type-simple">
                <div className="top-product-section">
                  <Link itemProp="url" to="/urban-leash" className="product-category">
                    <span className="image-wrapper">
                      <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Leash-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>          </span>
                  </Link>
                </div>
                <Link itemProp="url" to="/urban-leash" className="product-category product-info">
                  <h6 itemProp="name" ref="bags_urban-leash">My Urban Leash</h6>
                </Link>
              </li>
              <li className="product type-product post-749 status-publish first instock product_cat-mybona has-post-thumbnail taxable shipping-taxable product-type-simple">
                <div className="top-product-section">
                  <Link itemProp="url" to="/urban-collar" className="product-category">
                    <span className="image-wrapper">
                      <img width="300" height="300" src="https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-300x300.jpg" class="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcset="https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Urban-Collar-Rock-Wave_L-size-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>          </span>
                  </Link>
                </div>
                <Link itemProp="url" to="/urban-collar" className="product-category product-info">
                  <h6 itemProp="name" ref="bags_urban-collar">My Urban Collar</h6>
                </Link>
              </li>
              </Slider>
            </ul>
          </div>
          <div className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-4 images qode-add-gallery-and-zoom-support" data-columns={4} style={{opacity: 1, transition: 'opacity .25s ease-in-out', clear: 'both'}}>
            {this.state.bagName === 'Catcher' || this.state.bagName === 'Inventor' || this.state.bagName === 'Passenger' ? (
            <div className style={{zIndex: 1, position: 'absolute', top: 0, right: 0, textAlign: 'center'}}><div style={{height: '50px', borderRadius: '50%', margin: '0px auto', backgroundImage: `url('assets/linings/${this.state.interiorLining}.jpg')`, width: '50px'}} /><p>LINING</p></div>
            ) : null}
            <figure className="woocommerce-product-gallery__wrapper">
              <Container3d onNameChange={this.handleNameChange} />
            </figure>
          </div>
          <div className="qode-single-product-summary">
            <div className="summary entry-summary">
              <div className="clearfix">
                <h1 className="product_title entry-title">{this.state.bagName}</h1>
                <ul id="bagbuilderslide" className="w-list-unstyled filter-ul product-options">
                  {/* // Step 1,2 */}
                  {step}
                </ul>
                <p className="price">
                  <span className="woocommerce-Price-amount amount">
                    {/*todo price currency */}
                    {(this.state.newPrice).toFixed(2)}&nbsp;
                    <span className="woocommerce-Price-currencySymbol">{this.state.activeSymbol}</span>
                  </span>
                </p>

                {/* Add to cart functionality */}
                {this.state.index === 4 || (isStep && this.state.index === 3) ? (
                  <form className="cart" action="https://bonabag.com/cart/" method="post" encType="multipart/form-data">
                    <div className="quantity buttons_added">
                      <input type="button" defaultValue="-" className="minus" />
                      <input type="text" id="quantity_5d433a0e87fd4" step={1} min={1} max name="quantity" defaultValue={1} title="Qty" className="input-text qty text" size={4} pattern="[0-9]*" inputMode="numeric" aria-labelledby="Challenger A Mermaid quantity" />
                      <input type="button" defaultValue="+" className="plus" />
                      <input type="hidden" name="cfwc-title-field" value={`Area-1: ${this.state.areaOneLeatherType}/${this.state.colorOne}, Area-2: ${this.state.areaTwoLeatherType}/${this.state.colorTwo}, Area-3: ${this.state.areaThreeLeatherType}/${this.state.colorThree}, Area-4: ${this.state.areaFourLeatherType}/${this.state.colorFour},${this.state.bagName === "Longi" ? ' Area-5: '+this.state.areaFiveKolonType+'/'+this.state.colorFive+',' : ""} Metal: ${this.state.metalElement}, Lining: ${this.state.interiorLining}, Monogram: ${this.state.monogramText}/${this.state.monogramColor}`}></input>
                      {/*todo price currency */}
                      <input type="hidden" name="price" value={(this.state.totalPrice).toFixed(2)}></input>
                    </div>
                    <button type="submit" name="add-to-cart" value={this.state.productId} className="single_add_to_cart_button button alt single_add_to_cart_button qbutton button alt">CONTINUE</button>
                    <input type="hidden" name="add-to-cart" value={this.state.productId}></input>
                    <input type="hidden" name="product_id" value={this.state.productId}></input>
                    <input type="hidden" name="variation_id" class="variation_id" value={this.state.variationId}></input>

                  </form>
                ) : null}

                {/* Social Media Area */}
                {this.state.index === 4 || (isStep && this.state.index === 3) ? (
                  <div>
                    <h5>SHARE</h5>
                    <p>It’s done and it is a beauty! ​Thanks for being so creative. Your friends should also see this unique design.</p>
                  </div>
                ) : null}
                {this.state.index === 4 || (isStep && this.state.index === 3) ? (
                  <div className="wc_ss_btns modern-theme-circle">
                    <ul>
                      <li className="facebook">
                        <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fmy.bonabag.com%2F${this.state.routeUrl}%2F`} title={`Share ${this.state.bagName} on Facebook`}>
                          <i className="fa fa-facebook" />
                          <span>Share</span>
                        </a>
                      </li>
                      <li className="twitter">
                        <a target="_blank" href="https://twitter.com/intent/tweet?status=I designed a bag and i liked it.​ What do you think? You can create your unique #Bonabag here: URL (bonabag.com/mybona) #MyBona" title={`Share ${this.state.bagName} on Twitter`}>
                          <i className="fa fa-twitter" />
                          <span>Share</span>
                        </a>
                      </li>
                      <li className="linkedin">
                        <a target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://my.bonabag.com/${this.state.routeUrl}/`} title={`Share ${this.state.bagName} on Linkedin`}>
                          <i className="fa fa-linkedin" />
                          <span>Share</span>
                        </a>
                      </li>
                      <li className="email">
                        <a target="_blank" href={`mailto:?subject=${this.state.bagName}&body=https://my.bonabag.com/${this.state.routeUrl}/%0D%0AWelcome! %0D%0AHappy to meet you. This page is created for you to uniquely design your Bonabag. Start with choosing a bag shape, then pick your favorite colors, add custom details and it's ready to be fully hand-crafred by our atelier. Pure artisan, pure "you". Is it a custom gift? Don't forget to add your special touch, note and gift-wrap at the final step.`} title={`Share ${this.state.bagName} on Email`}>
                          <i className="fa fa-envelope" />
                          <span>Mail</span>
                        </a>
                      </li>
                    </ul>
                    <span className="wc_ss_btns_flex" />
                    <span>Share this product!</span>
                  </div>
                ) : null}

                {this.state.bagName ? (
                  <div className="product_meta">

                    {/* Description section */}
                    <button type="button" class="collapsible-additional-info" onClick={this.handleExpandingList}>description</button>
                    <div class="additional-info-content">

                      {/* Choose which description to use depending on the displayed bag */}
                      {this.state.bagName === 'Mini Dualer' || this.state.bagName === 'Mini Dualer Sunset' ? (
                        <div class="additional-info">
                         <p>Rooted in the dualism between humankind and nature, Dualer’s iconic design blends the state of being divided & the art of integrity.</p>
                         <p>Inspired by the arch shape, its curved structure represents transitions between separate places. Choose your Dualer & get ready for a multiway journey!</p>
                         </div>
                      ) : this.state.bagName === 'Midi Dualer' || this.state.bagName === 'Midi Dualer Lavatera' ? (
                        <div class="additional-info">
                          <p>Rooted in the dualism between humankind and nature, Dualer’s iconic design blends the state of being divided & the art of integrity.</p>
                          <p>Inspired by the arch shape, its curved structure represents transitions between separate places. Choose your Dualer & get ready for a multiway journey!</p>
                        </div>
                      ) : this.state.bagName === 'Maxi Dualer' || this.state.bagName === 'Maxi Dualer Fuchsia' ? (
                        <div class="additional-info">
                          <p>Rooted in the dualism between humankind and nature, Dualer’s iconic design blends the state of being divided & the art of integrity.</p>
                          <p>Inspired by the arch shape, its curved structure represents transitions between separate places. Choose your Dualer & get ready for a multiway journey!</p>
                        </div>
                      ) : this.state.bagName === 'Longi' ? (
                        <div class="additional-info">
                          <p>Inspired by the arch shape, Longi’s iconic design with a tube-like curved structure is based on transitions between separate places.</p>
                          <p>Comes with a detachable strap to transform the look from day to night or casual to glamor. Choose your Longi & get ready for a multiway journey!</p>
                        </div>
                      ) : this.state.bagName === 'Dual Chain Leash' ? (
                        <div class="additional-info">
                          <p>Extra durability with sophisticated design: Dual Chain Leash represents the strong bond between you & your loved one.</p>
                          <p>Grab the Dual Chain Leash & you’re ready to go for a walk as a stylish duo.</p>
                        </div>
                      ) : this.state.bagName === 'Dual Collar' ? (
                        <div class="additional-info">
                          <p>Blending elevated comfort with sophisticated design, Dual Collar reflects the bond between you & your loved one.</p>
                          <p>Grab the Dual Collar & go for a long walk with your little one.</p>
                        </div>
                      ) : this.state.bagName === 'Dual Harness' || this.state.bagName === 'Dual Harness Candy (M Size)' || this.state.bagName === 'Dual Harness Sky (M Size)'  ? (
                        <div class="additional-info">
                          <p>As a reflection of the bond between you & your loved one, Dual Harness blends elevated comfort with sophisticated design.</p>
                          <p>Dual Harness hugs your little one with the finest details & a unique charm.</p>
                        </div>
                      ) : this.state.bagName === 'Dual Leash' ? (
                        <div class="additional-info">
                          <p>With a perfect blend of elevated comfort & sophisticated design, Dual Leash reflects the bond between you & your loved one.</p>
                          <p>Grab the Dual Leash & enjoy a long run as a stylish duo.</p>
                        </div>
                      ) : this.state.bagName === 'Macro Pucker' ? (
                        <div class="additional-info">
                          <p>Shirred and tasselled. Elliptic opening shape. Unique sided shape like a piece of art. Pre-modern and oversize handle.</p>
                          <p>Large interior volume. Indispensable for both work life and daily life. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Micro Pucker' ? (
                        <div class="additional-info">
                            <p>Shirred and tasseled. Elliptic opening shape. Unique sided shape like a piece of art. Crossbody strap seems as long tassels.</p>
                            <p>Looks mini bag, but still large interior volume. Inspired by and in harmony with nature. Colours of earth and sky</p>
                        </div>
                      ) : this.state.bagName === 'Mini Styler' ? (
                        <div class="additional-info">
                              <p>Icon-shaped laser cuts. Horizontal rectangular geometric figure. Long tasseled, hippie style. Looks mini bag, but still large interior volume.</p>
                              <p>Indispensable for both work life and daily life. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Midi Styler' ? (
                        <div class="additional-info">
                                <p>Icon-shaped laser cuts. Horizontal rectangular geometric figure. Short tasseled, hippie style. Large interior volume.</p>
                                <p>Indispensable for both work life and daily life. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Chain Leash' ? (
                        <div class="additional-info">
                                  <p>It is all about your pets’ safety. Artisanal pet leash with elegant and unique chain. Handcrafted finest materials.</p>
                                  <p>Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Urban Leash' ? (
                        <div class="additional-info">
                          <p>Icon-shaped laser cuts. Pure harmony with you and your little friends.</p>
                          <p>Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Urban Collar' ? (
                        <div class="additional-info">
                                      <p>Icon-shaped laser cuts. Elevated comfort and sophisticated design.</p>
                                      <p>Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      ) : this.state.bagName === 'Verti Trundler' ? (
                          <div class="additional-info">
                              <p>All about pureness. Vertical rectangular geometric figure. Rolled shape. Soft feeling, suede details. Suitable to use as both shoulder bag and backpack.</p>
                              <p>Indispensable for travel. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      )  : this.state.bagName === 'Trundler' ? (
                        <div class="additional-info">
                            <p>All about pureness. Square geometric figure. Rolled shape. Soft feeling, suede details. Suitable to use as both shoulder bag and backpack.</p>
                            <p>Indispensable for travel. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      )  : this.state.bagName === 'Dreamer' ? (
                        <div class="additional-info">
                          <p>Best buddy of daily life. Comfortable body bag with an elegant effect. Handcrafted with a laser-cut flexi pocket.</p>
                         <p>&nbsp;</p>
                         <p>Easy access to your small things. Dream design for dreamers! Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      )  : this.state.bagName === 'Urban Case' ? (
                        <div class="additional-info">
                          <p>Work life companion. Mindfully designed both a sleeve and a shoulder briefcase. Handcrafted with a laser-cut flexi pocket.</p>
                         <p>&nbsp;</p>
                         <p>Easy access to your cables and battery charger. Inspired by and in harmony with nature. Colours of earth and sky.</p>
                        </div>
                      )   : null}
                    </div>


                    {/* Additional info section */}
                    <button type="button" class="collapsible-additional-info" onClick={this.handleExpandingList}>additional information</button>
                    <div class="additional-info-content">

                      {/* Choose what additional information to use depending on the bag */}
                      {this.state.bagName === 'Mini Dualer' || this.state.bagName === 'Mini Dualer Sunset' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>MINI DUALER</strong> W 14 cm x H 9,5 cm x D 6 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>STRAP</strong>&nbsp; DH 52 cm x L 130 cm x W 1,2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Grainy Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Midi Dualer' || this.state.bagName === 'Midi Dualer Lavatera' ? (
                        <div class="additional-info">
                          	<h3>Dimensions</h3>
                            <p>&nbsp;</p>
                            <p><strong>MIDI DUALER</strong> W 16 cm x H 16 cm x D 8 cm</p>
                            <p>&nbsp;</p>
                            <p><strong>STRAP</strong>&nbsp; DH 45 cm x L 130 cm x W 1,5 cm</p>
                            <p>&nbsp;</p>
                            <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                            <p>&nbsp;</p>
                            <h3>Compositions</h3>
                            <p>&nbsp;</p>
                            <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                            <p>&nbsp;</p>
                            <h3>Materials</h3>
                            <p>&nbsp;</p>
                            <p>Leather: Grainy Genuine Leather, Hardware: Brass, Lining: Twill</p>
                         </div>
                      ) : this.state.bagName === 'Maxi Dualer' || this.state.bagName === 'Maxi Dualer Fuchsia' ? (
                        <div class="additional-info">
						           	  	<h3>Dimensions</h3>
                            <p>&nbsp;</p>
                            <p><strong>MAXI DUALER</strong> W 30 cm x H 18 cm x D 8 cm</p>
                            <p>&nbsp;</p>
                            <p><strong>SHIPPING</strong>&nbsp;W 50 cm x H 31 cm x D 15 cm</p>
                            <p>&nbsp;</p>
                            <h3>Compositions</h3>
                            <p>&nbsp;</p>
                            <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                            <p>&nbsp;</p>
                            <h3>Materials</h3>
                            <p>&nbsp;</p>
                            <p>Leather: Grainy Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Longi' ? (
                        <div class="additional-info">
						           	  <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>LONGI</strong> W 30 cm x H 9,5 cm x D 8 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>STRAP</strong>&nbsp; DH 65 cm x L 130 cm x W 4 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Dual Chain Leash' ? (
                        <div class="additional-info">
						              <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>DUAL CHAIN LEASH</strong> L 120 cm x W 2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Grainy Genuine Leather, Hardware: Brass</p>
                          <p>&nbsp;</p>
                        </div>
                      ) : this.state.bagName === 'Dual Collar' ? (
                        <div class="additional-info">
						              <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>DUAL COLLAR L SIZE</strong> L 50 cm x W 2,5 cm x 5 points</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL COLLAR M SIZE</strong> L 40 cm x W 2 cm x 5 points</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL COLLAR S SIZE</strong> L 30 cm x W 1,5 cm x 5 Points</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Grainy Genuine Leather, Hardware: Brass</p>
                        </div>
                      ) : this.state.bagName === 'Dual Harness' || this.state.bagName === 'Dual Harness Candy (M Size)' || this.state.bagName === 'Dual Harness Sky (M Size)'  ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>DUAL HARNESS L SIZE</strong> R 25 cm x W 2,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL HARNESS M SIZE</strong> R 20 cm x W 2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL HARNESS S SIZE</strong> R 15 cm x W 1,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Grainy Genuine Leather, Hardware: Brass</p>
                          <p>&nbsp;</p>
                        </div>
                      ) : this.state.bagName === 'Dual Leash' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>DUAL LEASH L SIZE</strong> L 130 cm x W 2,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL LEASH M SIZE</strong> L 120 cm x W 2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>DUAL LEASH S SIZE</strong> L 110 cm x W 1,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                          <p>Leather: Grainy Genuine Leather, Hardware: Brass</p>
                          <p>&nbsp;</p>
                        </div>
                      ) : this.state.bagName === 'Macro Pucker' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>MACRO PUCKER</strong> W 40 cm x H 20 cm x D 20 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Micro Pucker' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>MICRO PUCKER</strong> W 24 cm x H 14 cm x D 14 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Mini Styler' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>MINI STYLER</strong> W 20 cm x H 11 cm x D 7 cm </p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Midi Styler' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>MIDI STYLER</strong> W 29 cm x H 17 cm x D 7 cm </p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Chain Leash' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>CHAIN LEASH</strong> L 120 cm x W 2,5 cm </p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Urban Leash' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>URBAN LEASH L SIZE</strong> L 130 cm x W 3 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>URBAN LEASH M SIZE</strong> L 120 cm x W 2,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>URBAN LEASH S SIZE</strong> 110 cm x W 2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Urban Collar' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>URBAN COLLAR L SIZE</strong> L 40 cm x W 3 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>URBAN COLLAR M SIZE</strong> L 35 cm x W 2,5 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>URBAN COLLAR S SIZE</strong> L 30 cm x W 2 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 30 cm x H 30 cm x D 7 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      ) : this.state.bagName === 'Verti Trundler' ? (
                        <div class="additional-info">
                        <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>VERTI TRUNDLER</strong> W 37 cm x H 30 cm x D 11 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>

                        </div>
                      )  : this.state.bagName === 'Trundler' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>TRUNDLER</strong> W 31 cm x H 24 cm x D 11 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING </strong> W 50 cm x H 31 cm x D 15 cm</p>
                          <p>&nbsp;</p>
                          <h3>Compositions</h3>
                          <p>&nbsp;</p>
                          <p>100% Genuine Leather, 100% Nickel-Free Hardware, 100% Cotton Lining</p>
                          <p>&nbsp;</p>
                          <h3>Materials</h3>
                          <p>&nbsp;</p>
                           <p>Leather: Smooth Genuine Leather, Hardware: Brass, Lining: Twill</p>
                        </div>
                      )  : this.state.bagName === 'Dreamer' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p>&nbsp;</p>
                          <p><strong>DREAMER BAG</strong> W 38 cm x H 47 cm</p>
                          <p>&nbsp;</p>
                          <p><strong>SHIPPING</strong> W 50 cm x H 31 cm x D 15 cm</p>
                        </div>
                      )    : this.state.bagName === 'Urban Case' ? (
                        <div class="additional-info">
                          <h3>Dimensions</h3>
                          <p><strong>URBAN CASE</strong> W 35 cm x H 30 cm</p>
                          <p><strong>SHIPPING</strong> W 50 cm x H 31 cm x D 15 cm</p>
                        </div>
                      )  : null}
                    </div>
                  </div>
                ) : null}

              </div>
              {/* .clearfix */}
            </div>
            {/* .summary */}
          </div>
          <div className=" up-sells upsells products">
            <h2 className="qode-related-upsells-title" style={{paddingTop: '0'}}>You may also like…</h2>
            <ul className="products">
               <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/men/bags-men/longis/longi/?attribute_pa_color=brown" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Bourbon-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/men/bags-men/longis/longi/?attribute_pa_color=brown" className="product-category product-info">
                        <h6 itemprop="name">Longi Bourbon</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>245,00</span></span>
                      </a>
              </li>
               <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/men/bags-men/longis/longi/?attribute_pa_color=butter" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Longi-Butter-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/men/bags-men/longis/longi/?attribute_pa_color=butter" className="product-category product-info">
                        <h6 itemprop="name">Longi Butter</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>245,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/maxi-dualer/?attribute_pa_color=stone-brown" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/maxi-dualer/?attribute_pa_color=stone-brown" className="product-category product-info">
                        <h6 itemprop="name">Maxi Dualer Cortado</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>260,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/maxi-dualer/?attribute_pa_color=powder-grey" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Maxi-Dualer-Marshmallow-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/maxi-dualer/?attribute_pa_color=powder-grey" className="product-category product-info">
                        <h6 itemprop="name">Maxi Dualer Marshmallow</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>260,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/midi-dualer/?attribute_pa_color=stone-brown" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/midi-dualer/?attribute_pa_color=stone-brown" className="product-category product-info">
                        <h6 itemprop="name">Midi Dualer Cortado</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>215,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/midi-dualer/?attribute_pa_color=powder-grey" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Midi-Dualer-Marshmallow-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/midi-dualer/?attribute_pa_color=powder-grey" className="product-category product-info">
                        <h6 itemprop="name">Midi Dualer Marshmallow</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>215,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/mini-dualer/?attribute_pa_color=stone-brown" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Cortado-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/mini-dualer/?attribute_pa_color=stone-brown" className="product-category product-info">
                        <h6 itemprop="name">Mini Dualer Cortado</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>145,00</span></span>
                      </a>
              </li>
              <li className="product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/mini-dualer/?attribute_pa_color=powder-grey" className="product-category">
                          <span className="image-wrapper">
                            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-1536x1536.jpg 1536w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2022/02/Mini-Dualer-Marshmallow-1.jpg 2000w" sizes="(max-width: 300px) 100vw, 300px"/>
                          </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/dualers/mini-dualer/?attribute_pa_color=powder-grey" className="product-category product-info">
                        <h6 itemprop="name">Mini Dualer Marshmallow</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>145,00</span></span>
                      </a>
              </li>
              <li className=" product type-product post-8993 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">
                  <div className="top-product-section">
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/macro-pucker/?attribute_pa_color=black-brown" className="product-category">
                          <span className="image-wrapper">
                          <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Choc-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
                      </a>
                  </div>
                      <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/macro-pucker/?attribute_pa_color=black-brown" className="product-category product-info">
                        <h6 itemprop="name">Macro Pucker Choc</h6>
              	        <span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>385,00</span></span>
                      </a>
              </li>
	<li className=" product type-product post-8994 status-publish outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/macro-pucker/?attribute_pa_color=sand-baby-blue" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Macro-Pucker-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
                  </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/macro-pucker/?attribute_pa_color=sand-baby-blue" className="product-category product-info">
        <h6 itemprop="name">Macro Pucker Rock &amp; Wave</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>385,00</span></span>
    </a>

</li>
	<li className=" product type-product post-9005 status-publish instock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/micro-pucker/?attribute_pa_color=black-brown" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Choc-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
        </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/micro-pucker/?attribute_pa_color=black-brown" className="product-category product-info">
        <h6 itemprop="name">Micro Pucker Choc</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>195,00</span></span>
    </a>

</li>
	<li className=" product type-product post-9006 status-publish last instock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/micro-pucker/?attribute_pa_color=sand-baby-blue" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Micro-Pucker-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
                  </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/puckers/micro-pucker/?attribute_pa_color=sand-baby-blue" className="product-category product-info">
        <h6 itemprop="name">Micro Pucker Rock &amp; Wave</h6>



	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>195,00</span></span>
    </a>

</li>
<li className=" product type-product post-9035 status-publish first instock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/trundlers/trundler/?attribute_pa_color=black-brown" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Choc-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
        </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/trundlers/trundler/?attribute_pa_color=black-brown" className="product-category product-info">
        <h6 itemprop="name">Trundler Choc</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>265,00</span></span>
    </a>

</li>
	<li className=" product type-product post-9036 status-publish instock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/trundlers/trundler/?attribute_pa_color=sand-baby-blue" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Trundler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
        </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/trundlers/trundler/?attribute_pa_color=sand-baby-blue" className="product-category product-info">
        <h6 itemprop="name">Trundler Rock &amp; Wave</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>265,00</span></span>
    </a>

              </li>
              <li className="product type-product post-9017 status-publish first outofstock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/stylers/midi-styler/?attribute_pa_color=black-brown" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Choc-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
        </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/stylers/midi-styler/?attribute_pa_color=black-brown" className="product-category product-info">
        <h6 itemprop="name">Midi Styler Choc</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>300,00</span></span>
    </a>
</li>
	<li className="product type-product post-9018 status-publish instock has-post-thumbnail taxable shipping-taxable purchasable product-type-variation woocommerce-single-variations-attribute-pa_color veb-variation-gallery-product">

    <div className="top-product-section">

        <a itemprop="url" href="https://bonabag.com/shop/women/bags/stylers/midi-styler/?attribute_pa_color=sand-baby-blue" className="product-category">
            <span className="image-wrapper">
            <img width={300} height={300} src="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail inwp-attachment-image" alt="" loading="lazy" srcSet="https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-300x300.jpg 300w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-1024x1024.jpg 1024w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-150x150.jpg 150w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-768x768.jpg 768w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-570x570.jpg 570w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-500x500.jpg 500w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-1000x1000.jpg 1000w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-700x700.jpg 700w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-600x600.jpg 600w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1-100x100.jpg 100w, https://bonabag.com/wp-content/uploads/2021/08/Midi-Styler-Rock-Wave-1.jpg 1200w" sizes="(max-width: 300px) 100vw, 300px"/>            </span>
        </a>

    </div>
        <a itemprop="url" href="https://bonabag.com/shop/women/bags/stylers/midi-styler/?attribute_pa_color=sand-baby-blue" className="product-category product-info">
        <h6 itemprop="name">Midi Styler Rock &amp; Wave</h6>

	<span className="price"><span className="woocommerce-Price-amount amount"><span className="woocommerce-Price-currencySymbol">€</span>300,00</span></span>
    </a>

</li>
            </ul>
          </div>

        </div>
      </Router>
    )
  }
}

export default App
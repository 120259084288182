// src/Container3d.js
import React, { Component } from "react";
import { Route } from "react-router-dom";

import MiniStyler from "./MiniStyler";
import MidiStyler from "./MidiStyler";
import MicroPucker from "./MicroPucker";
import MacroPucker from "./MacroPucker";
import Trundler from "./Trundler";
import VertiTrundler from "./VertiTrundler";
import UrbanLeash from "./UrbanLeash";
import UrbanCollar from "./UrbanCollar";
import ChainLeash from "./ChainLeash";
import Dreamer from "./Dreamer";
import UrbanCase from "./UrbanCase"; // Urban Case Unisex
import MiniDualer from "./MiniDualer";
import MiniDualerSunset from "./MiniDualerSunset";
import MidiDualer from "./MidiDualer";
import MidiDualerLavatera from "./MidiDualerLavatera";
import MaxiDualer from "./MaxiDualer";
import MaxiDualerFuchsia from "./MaxiDualerFuchsia";
import Longi from "./Longi";
import DualChainLeash from "./DualChainLeash";
import DualHarness from "./DualHarness";
import DualHarnessCandy from "./DualHarnessCandy";
import DualHarnessSky from "./DualHarnessSky";
import DualCollar from "./DualCollar";
import DualLeash from "./DualLeash";



class Container3d extends Component {
	render() {
		return (
			<div className="container3d">
				<img class="loading_image" src="/Bonabag-Logo-Only.png"/>
				<Route
					exact
					path="/"
					render={props => (
						<Longi {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/mini-styler"
					render={props => (
						<MiniStyler {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/midi-styler"
					render={props => (
						<MidiStyler {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/micro-pucker"
					render={props => (
						<MicroPucker {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/macro-pucker"
					render={props => (
						<MacroPucker {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/trundler"
					render={props => (
						<Trundler {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/verti-trundler"
					render={props => (
						<VertiTrundler {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/urban-leash"
					render={props => (
						<UrbanLeash {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/urban-collar"
					render={props => (
						<UrbanCollar {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/chain-leash"
					render={props => (
						<ChainLeash {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
					<Route
					path="/urban-case"
					render={props => (
						<UrbanCase {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dreamer"
					render={props => (
						<Dreamer {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
					<Route
					path="/mini-dualer"
					render={props => (
						<MiniDualer {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/mini-dualer-sunset"
					render={props => (
						<MiniDualerSunset {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/midi-dualer"
					render={props => (
						<MidiDualer {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/midi-dualer-lavatera"
					render={props => (
						<MidiDualerLavatera {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/maxi-dualer"
					render={props => (
						<MaxiDualer {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/maxi-dualer-fuchsia"
					render={props => (
						<MaxiDualerFuchsia {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/longi"
					render={props => (
						<Longi {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-chain-leash"
					render={props => (
						<DualChainLeash {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-harness"
					render={props => (
						<DualHarness {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-harness-candy"
					render={props => (
						<DualHarnessCandy {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-harness-sky"
					render={props => (
						<DualHarnessSky {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-collar"
					render={props => (
						<DualCollar {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
				<Route
					path="/dual-leash"
					render={props => (
						<DualLeash {...props} onNameChange={this.props.onNameChange} />
					)}
				/>
			</div>
		);
	}
}

export default Container3d;

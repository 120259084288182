// Import our dependancies
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import {
	Scene,
	Engine,
	AssetsManager,
	ArcRotateCamera,
	Vector3,
	HemisphericLight,
	Mesh,
	Color3,
	Tools,
	MeshBuilder,
	Texture,
	StandardMaterial,
	Axis,
	DynamicTexture,
	VertexBuffer,
	VertexData
} from "babylonjs";

// Here we extend Reacts component class
class Dreamer extends Component {
	// Config Model

	//Birincil deri tipi
	primaryLeatherMeshIDs = [
		"16fa4826-0cdc-4c58-8b77-8a16f0b0ffe9",
		"d8b62493-3807-4346-a9cd-747ba63478cb",
		"76d6f871-8ee0-48a2-952b-c893247508b1",
		"be05b9ec-1f5f-420b-a8d8-142cac53fe2e",
	];

	//Monogram özel alanı
	secondryLeatherMeshIDs = [
		"b5ce0bc2-27c4-4e1d-8f33-e91aafaea040"
	];

	//İkincil Deri Tipi
	secondryLeatherMeshIDs2 = [
		"262c1f18-ca6c-4fff-9a50-b66cbee917ff",
		"1e20ace4-d5d5-4839-96c4-91608b71a557",
		"778c975c-5b6d-49f2-8416-a728f61c0c3e",
		"7ebc8be5-9886-47c6-ad4e-15d31e8f5418",
		"bf6f1965-2320-4f8a-b96a-e38b822dff0e",
	];

	//İç Deri
    interiorMeshIDs = [
		"a3f0bb3d-86b3-47c6-ba14-2cdcdbd40134", //arka astar
		"985c3341-223e-45a4-ad7a-d3e2403b5ef8" //ön astar
	];

	//Metal IDler
	metalMeshIDs = [
		"a338a76f-e666-4c3a-884c-68663af4c042",
		"696c9b33-57cd-41c6-bfea-4e6aee07c81f",
		"49586381-539d-4ca3-a9d2-5e288dbf9161",
		"a6f51752-4d4f-4d01-a2f4-b34ce5a10f7c",
		"9ed43052-e854-439e-b8ac-9a74b8b444fb",
		"beb2e5f0-189e-4560-9be6-458eddbf896e",
		"e357ff01-f1dc-4c53-82a8-39df01fd96a9",
		"0147d341-b6a2-4674-aa3b-b5133d4bea18",
		"49cbbc54-6814-4efa-8c3d-9e2fdbf2cae5",
		"6b04de91-f61c-48ba-a57f-dbdacd76690b",
		"4749b1d9-accc-4a50-9187-1342449d27a2",
		"99b6c880-3633-484d-981a-169a32ceb74d",
		"02210cbe-c399-4421-a412-ad623d1b6edd",
		"010f2317-9f74-4dc9-a281-af647ec563b7",
		"533ff3b1-100a-4a82-9e75-ad40c6b0fabc",
		"5c2e9a66-3ab9-4973-8330-15ad6a827877",
		"845dde4b-769a-40ab-8b14-9ad608853e10",
		"19826372-d243-4e54-ab43-42fb3cef623b",
		"2b6d31d3-673e-42c5-a817-28d7c2649de7",

		//"62d287b1-56b7-4626-bebf-782cd9aff0f7", //fermuar kumas
	];

	//Kemerin Dışı
	primaryBeltMeshID = [
		"183b6a15-e2a2-459d-abef-ba3b582c4d2b",
		"536315e7-3768-44e4-92ff-2e826b47a647",
		"8a518af1-407f-45b2-9107-9d988102d101",
	];
	//Kemerin İçi
	secondaryBeltMeshID = [
	];

	config = {
		name : 'Dreamer',
		product_id : 9609,//WordPress Ürün ID
        price: 405,//WordPress Ürün Euro Fiyatı
		variation_id : 9611,//WordPress Ürün Varyasyon ID
		model_file : 'dreamer.babylon',
		primary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0
		},
		//monogram config
		secondary : {
			u_scale: 0.4,
			v_scale: 0.4,
			u_offset: 2.1,
			v_offset: -0
		},
		//ikincil deri config
		secondary2 : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0
		},
		primary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		secondary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		interior : {
			u_scale: 0.50,
			v_scale: 0.50
		},
		//Monogram Alanın ID si
		text : {
			mesh_id: 'b5ce0bc2-27c4-4e1d-8f33-e91aafaea040',
			font: 'bold 450px mymonospace',
			scale: 1,
			width: 512 *10,
			height: 512 *10,
			left: null,
			top: null
		},
		camera : { // Original Camera
			alpha: 280,
			beta: 75,
			radius: 800,
			upper_limit: 1500,
			lower_limit: 50,
			vector_x: 280,
			vector_y: 78,
			vector_z: 35,
			speed: 3.754,
			wheel: 0.01
		},
		interior_camera : { // When select lining goes to cam position
			alpha: 0,
			beta: 2.6,
			radius: 300,
		},
		monogram_camera : { // monogram camera
			alpha: 300,
			beta: 135,
			radius: 650,
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			monogram: "",
			textureType: "Smooth-patterned",
			texture: "Smooth-Black",
			metal: "matte_black",
		};
		// We bind our events to keep the proper "this" context.
		this.changeTextureOne = this.changeTextureOne.bind(this);
		this.changeTextureTwo = this.changeTextureTwo.bind(this);
		this.changeText = this.changeText.bind(this);
		this.loadModels = this.loadModels.bind(this);
	}

	changeTextureOne = e => {
		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['primary'].u_scale;
		material1.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		material1.diffuseTexture.uOffset = this.config['primary'].u_offset;
		material1.diffuseTexture.vOffset = this.config['primary'].v_offset;

		// Clones the materials that needs to get changed
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});
	};

	changeTextureTwo = e => {
		this.setState({
			textureType: e.detail.textureType,
			texture: e.detail.texture
		});

		// Adding new Texture material //ikincil alan
		var texture1 = new StandardMaterial("texture1", this.scene);
		texture1.specularColor = new Color3(0.25, 0.25, 0.25);
		texture1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture1.diffuseTexture.uScale = this.config['secondary2'].u_scale;
		texture1.diffuseTexture.vScale = this.config['secondary2'].v_scale;

		// Offset texture
		texture1.diffuseTexture.uOffset = this.config['secondary2'].u_offset;
		texture1.diffuseTexture.vOffset = this.config['secondary2'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs2.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material // monogram
		this.secondryLeatherMeshIDs2.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture1;
		});

		// Adding new Texture material
		var texture2 = new StandardMaterial("texture2", this.scene);
		texture2.specularColor = new Color3(0.25, 0.25, 0.25);
		texture2.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture2.diffuseTexture.uScale = this.config['secondary'].u_scale;
		texture2.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		texture2.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		texture2.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture2;
		});

		// Keep the text when changing tha texture again
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		//innerGround.scaling.scale(this.config['text'].scale);
		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			"white",
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	changeBeltOne = e => {
		var material3 = new StandardMaterial("belt1", this.scene);
		material3.specularColor = new Color3(0.25, 0.25, 0.25);
		material3.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material3.diffuseTexture.uScale = this.config['primary_belt'].u_scale;
		material3.diffuseTexture.vScale = this.config['primary_belt'].v_scale;

		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material3;
		});
	};

	changeBeltTwo = e => {
		var material4 = new StandardMaterial("belt1", this.scene);
		material4.specularColor = new Color3(0.25, 0.25, 0.25);
		material4.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material4.diffuseTexture.uScale = this.config['secondary_belt'].u_scale;
		material4.diffuseTexture.vScale = this.config['secondary_belt'].v_scale;

		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material4;
		});
	};

	changeMetal = e => {
		var material2 = new StandardMaterial("metal1", this.scene);
		material2.diffuseTexture = new Texture(
			`assets/metals/${e.detail.metal}.jpg`,
			this.scene
		);

		// Clones the materials that needs to get changed
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material2;
		});
	};

	changeInterior = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['interior_camera'].radius,
			alpha: this.config['interior_camera'].alpha,
			beta: this.config['interior_camera'].beta,
			ease: Power2.easeOut
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/linings/${e.detail.interior}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['interior'].u_scale;
		material1.diffuseTexture.vScale = this.config['interior'].v_scale;

		// Clones the materials that needs to get changed
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});
	};

	changeText = e => {
		this.setState(
			{
				monogram: e.detail.text
			},
			() => {
				// Adding Text
				var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
				var innerGround = ground.clone("innerGround");
				//innerGround.scaling.scale(this.config['text'].scale);

				//Create dynamic texture
				var textureResolution = 512;
				var textureGround = new DynamicTexture(
					"dynamic texture",
					{ width: this.config['text'].width, height: this.config['text'].height },
					this.scene
				);
				textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
				textureGround.hasAlpha = true;

				var font = this.config['text'].font;
				textureGround.drawText(
					this.state.monogram,
					this.config['text'].left,
					this.config['text'].top,
					font,
					"white",
					null,
					true,
					true
				);
				//textureGround.wrapV = 1;

				var materialGround = new StandardMaterial("Mat", this.scene);
				materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
				materialGround.diffuseTexture = textureGround;

				// Scale texture
				materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
				materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

				// Offset texture
				materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
				materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

				var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
				innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

				innerGroundMaterial.diffuseTexture = new Texture(
					`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
					this.scene
				);

				// Scale texture
				innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
				innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

				// Offset texture
				innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
				innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

				ground.material = materialGround;
				innerGround.material = innerGroundMaterial;
			}
		);
	};

	changeTextColor = e => {
		// Adding Text
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		innerGround.scaling.scale(this.config['text'].scale);

		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;
		var textureContext = textureGround.getContext();

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			e.detail.color,
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	// Makes the canvas behave responsively
	onResizeWindow = () => {
		if (this.engine) {
			this.engine.resize();
		}
	};

	// Sets up our canvas tag for webGL scene
	setEngine = () => {
		this.stage.style.width = "200%";
		this.stage.style.height = "200%";
		this.engine = new Engine(this.stage);
		this.stage.style.width = "100%";
		this.stage.style.height = "100%";
	};

	// Creates the scene graph
	setScene = () => {
		this.scene = new Scene(this.engine);

		// Background
		this.scene.clearColor = new Color3(255, 255, 255);
	};

	// Adds camera to our scene.
	setCamera = () => {
		this.camera = new ArcRotateCamera(
			"Camera",
			Tools.ToRadians(this.config['camera'].alpha),
			Tools.ToRadians(this.config['camera'].beta),
			this.config['camera'].radius,
			new Vector3(this.config['camera'].vector_x, this.config['camera'].vector_y, this.config['camera'].vector_z),
			this.scene
		);
		this.camera.attachControl(this.stage, false);
		this.camera.lowerRadiusLimit = this.config['camera'].lower_limit;
		this.camera.upperRadiusLimit = this.config['camera'].upper_limit;
		this.camera.wheelDeltaPercentage = this.config['camera'].wheel;
		this.camera.speed = this.config['camera'].speed;
	};

	moveCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['camera'].radius,
			alpha: Tools.ToRadians(this.config['camera'].alpha),
			beta: Tools.ToRadians(this.config['camera'].beta),
			ease: Power2.easeOut
		});
	};

	moveMonogramCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['monogram_camera'].radius,
			alpha: Tools.ToRadians(this.config['monogram_camera'].alpha),
			beta: Tools.ToRadians(this.config['monogram_camera'].beta),
			ease: Power2.easeOut
		});
	};

	loadModels = () => {
		let loader = new AssetsManager(this.scene);
		// Arguments: "ID", "Root URL", "URL Prefix", "Filename"
		let loadBagModel = loader.addMeshTask(
			"bonabag",
			"",
			"",
			this.config['model_file']
		);

		/*
			Loader is given a callback to run when the model has loaded
			the variable t is our imported scene. You can use
			it to examine all the mesh's loaded.
		*/

		loadBagModel.onSuccess = t => {

			// Adding new Texture material monogram
			var texture_def = new StandardMaterial("texture_def", this.scene);
			texture_def.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def.diffuseTexture.uScale = this.config['secondary'].u_scale;
			texture_def.diffuseTexture.vScale = this.config['secondary'].v_scale;

			// Offset texture
			texture_def.diffuseTexture.uOffset = this.config['secondary'].u_offset;
			texture_def.diffuseTexture.vOffset = this.config['secondary'].v_offset;

			let meshIDs = this.secondryLeatherMeshIDs.concat();

			// Clones the materials that needs to get changed
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def;
			});


			// Adding new Texture material 2
			var texture_def2 = new StandardMaterial("texture_def2", this.scene);
			texture_def2.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def2.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def2.diffuseTexture.uScale = this.config['secondary2'].u_scale;
			texture_def2.diffuseTexture.vScale = this.config['secondary2'].v_scale;

			// Offset texture
			texture_def2.diffuseTexture.uOffset = this.config['secondary2'].u_offset;
			texture_def2.diffuseTexture.vOffset = this.config['secondary2'].v_offset;

			let meshIDs2  = this.primaryLeatherMeshIDs.concat(
				this.secondryLeatherMeshIDs2,
				this.interiorMeshIDs,
				this.primaryBeltMeshID,
				this.secondaryBeltMeshID
			);

			// Clones the materials that needs to get changed
			meshIDs2.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs2.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def2;
			});

			// Metal Components Default Texture
			var metal_def = new StandardMaterial("metal1", this.scene);
			metal_def.diffuseTexture = new Texture(
				`assets/metals/${this.state.metal}.jpg`,
				this.scene
			);

			// Clones the materials that needs to get changed
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = metal_def;
			});


			// Start the animation loop once the model is loaded
			this.engine.runRenderLoop(() => {
				this.scene.render();
			});

			// The model came in a little dark so lets add some extra light
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(-1, -1, 0.5), this.scene);
		};

		// It also calls an Error callback if something goes wrong
		loadBagModel.onError = function(task, message, exception) {
			console.log(message, exception);
		};

		if (this.engine) {
			this.engine.resize();
		}

		// We return the fully configured loader
		return loader;
	};

	//Build the scene when the component has been loaded.
	componentDidMount() {
		// Change the displayed bag name
		this.props.onNameChange(this.config['name'], this.config['price'], this.config['product_id'],this.config['variation_id']);

		this.setEngine();
		this.setScene();
		this.setCamera();
		this.loadModels().load();

		// We can add our custom events just like any other DOM event
		window.addEventListener("resize", this.onResizeWindow);
		window.addEventListener("move-camera", this.moveCamera);
		window.addEventListener("move-monogram-camera", this.moveMonogramCamera);
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
		window.addEventListener("change-belt-two", this.changeBeltTwo);
		window.addEventListener("change-metal", this.changeMetal);
		window.addEventListener("change-interior", this.changeInterior);
		window.addEventListener("change-text", this.changeText);
		window.addEventListener("change-text-color", this.changeTextColor);
	}
	//Renderes our Canvas tag and saves a reference to it.
	render() {
		return <canvas className="scene" ref={el => (this.stage = el)}></canvas>;
	}
}

//returns the scene to be used by other components
export default Dreamer;
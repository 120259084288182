// src/Components/Controls.js
import ReactDOM from 'react-dom';
import React, { Component } from 'react'

class FirstStep extends Component {

    isStep;
    notPriLeather;
    notSecLeather;
    notPriBelt;
    notSecBelt;
    notKolon;

    constructor(props) {
        super(props);

        this.state = {
            textures: {
                'Grainy-patterned': ['Grainy-Baby_Blue', 'Grainy-Black', 'Grainy-Butter', 'Grainy-Fuchsia', 'Grainy-Khaki', 'Grainy-Off_White','Grainy-Orange','Grainy-Sugar_Pink','Grainy-White'],
                'Smooth-patterned': ['Smooth-Baby_Blue', 'Smooth-Black', 'Smooth-Burgundy', 'Smooth-Dark_Brown', 'Smooth-Dark_Gray','Smooth-Dark_Green','Smooth-Gray','Smooth-Ice_Blue','Smooth-Light_Gray','Smooth-Mustard','Smooth-Powder','Smooth-Red','Smooth-Rose','Smooth-Salmon','Smooth-Sand','Smooth-Stone','Smooth-White','Smooth-Yellow'],
                'Suede-patterned': ['Suede_Baby-Blue','Suede_Black','Suede_Brick','Suede_Bright-Navy','Suede_Burgundary','Suede_Coral','Suede_Dark-Brown','Suede_Dark-Gray','Suede_Dark-Green','Suede_Dark-Navy','Suede_Light-Gray','Suede_Mink','Suede_Mustard','Suede_Night-Blue','Suede_Olive-Green','Suede_Red','Suede_Salmon','Suede_Yellow'],
            },
            texturesKolon:{
                'Woven-patterned': ['Woven-Beige','Woven-Black','Woven-Burgundy','Woven-Caramel','Woven-Coffee','Woven-Khaki','Woven-NavyBlue','Woven-Orange','Woven-Red']
            },
            primaryLeatherType: 'Smooth-patterned',
            secondryLeatherType: 'Smooth-patterned',
            primaryBeltLeatherType: 'Smooth-patterned',
            secondaryBeltLeatherType: 'Smooth-patterned',
            kolonTextureType: 'Woven-patterned'
        }

        this.updatePrimaryLeatherType = this.updatePrimaryLeatherType.bind(this);
        this.updateSecondryLeatherType = this.updateSecondryLeatherType.bind(this);
        this.updatePrimaryBeltLeatherType = this.updatePrimaryBeltLeatherType.bind(this);
        this.updateSecondaryBeltLeatherType = this.updateSecondaryBeltLeatherType.bind(this);
        this.updateKolonTextureType = this.updateKolonTextureType.bind(this);
        this.handleLeftClick = this.handleLeftClick.bind(this);
        this.handleRightClick = this.handleRightClick.bind(this);

        //3 step olanlar sayı düzenleme
        var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)'];
		this.isStep = bags.includes(this.props.bagName)

        //primary leather rengi olmayan
        var notPriList = ['Dual Chain Leash'];
		this.notPriLeather = notPriList.includes(this.props.bagName)

        //secondary leather rengi olmayan
        var notSecList = ['Dual Leash','Dual Chain Leash','Dual Collar'];
		this.notSecLeather = notSecList.includes(this.props.bagName)

        //primary belt rengi olmayan
        var notPriBeltList = ['Chain Leash','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)','Longi']
        this.notPriBelt = notPriBeltList.includes(this.props.bagName)

        //secondary belt rengi olmayan
        var notSecBeltList = ['Urban Leash','Urban Collar','Chain Leash','Macro Pucker','Micro Pucker','Urban Case','Dreamer','Maxi Dualer','Maxi Dualer Fuchsia','Midi Dualer','Midi Dualer Lavatera','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)','Longi']
        this.notSecBelt = notSecBeltList.includes(this.props.bagName)

        var notKolonList = ['Longi']
        this.notKolon = notKolonList.includes(this.props.bagName)

        /*var bagsCustom = ['Maxi Dualer Fuchsia','Midi Dualer Lavatera','Mini Dualer Sunset','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isCustomModel = bagsCustom.includes(this.state.bagName)
        if(isCustomModel){
            this.props.onStepRight();
		let event;
        //sadece iç alanda metal olan
		var bags = ['Maxi Dualer','Maxi Dualer Fuchsia']
		var isRight = bags.includes(this.props.bagName)
		if (isRight) {
			event = new CustomEvent("move-interior-camera", {});
	   }
	   else{
			event = new CustomEvent("move-camera", {});
	   }
	   window.dispatchEvent(event);
        }*/
    }

    handleLeftClick() {
        this.props.onStepLeft();
    }

    handleRightClick() {
        this.props.onStepRight();
		let event;
        //sadece iç alanda metal olan
		var bags = ['Maxi Dualer','Maxi Dualer Fuchsia']
		var isRight = bags.includes(this.props.bagName)
		if (isRight) {
			event = new CustomEvent("move-interior-camera", {});
	   }
	   else{
			event = new CustomEvent("move-camera", {});
	   }
	   window.dispatchEvent(event);
    }

    emitTextureOneChangeEvent(textureType, texture) {
        ReactDOM.findDOMNode(this.refs['primary-option-group']).classList.add('checked');

        let event = new CustomEvent('change-texture-one', { detail: {
            textureType,
            texture
        }})
        window.dispatchEvent(event)
    }

    emitTextureTwoChangeEvent(textureType, texture) {
        ReactDOM.findDOMNode(this.refs['secondary-option-group']).classList.add('checked');

        let event = new CustomEvent('change-texture-two', { detail: {
            textureType,
            texture
        }})
        window.dispatchEvent(event)
    }

    emitPrimaryBeltChangeEvent(textureType, texture) {
        ReactDOM.findDOMNode(this.refs['primary-belt-option-group']).classList.add('checked');

        let event = new CustomEvent('change-belt-one', { detail: {
            textureType,
            texture
        }})
        window.dispatchEvent(event)
    }

    emitSecondaryBeltChangeEvent(textureType, texture) {
        ReactDOM.findDOMNode(this.refs['secondary-belt-option-group']).classList.add('checked');

        let event = new CustomEvent('change-belt-two', { detail: {
            textureType,
            texture
        }})
        window.dispatchEvent(event)
    }

    emitKolonTextureChangeEvent(textureType, texture) {
        ReactDOM.findDOMNode(this.refs['kolon-option-group']).classList.add('checked');

        let event = new CustomEvent('change-kolon', { detail: {
            textureType,
            texture
        }})
        window.dispatchEvent(event)
    }

    updatePrimaryLeatherType(e) {
        if(e.target.value=="Grainy-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info']).textContent="";
        }else if(e.target.value=="Smooth-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info']).textContent="";
        }else {
            ReactDOM.findDOMNode(this.refs['leather_info']).textContent=" ";
        }
        this.setState({
            primaryLeatherType: e.target.value
        })
        this.props.onAddingPrimaryPrice(e.target.value)
    }

    updateSecondryLeatherType(e) {
        if(e.target.value=="Grainy-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info2']).textContent="";
        }else if(e.target.value=="Smooth-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info2']).textContent="";
        }else {
            ReactDOM.findDOMNode(this.refs['leather_info2']).textContent=" ";
        }
        this.setState({
            secondryLeatherType: e.target.value
        })
        this.props.onAddingSecondryPrice(e.target.value)
    }

    updatePrimaryBeltLeatherType(e) {
        if(e.target.value=="Grainy-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info3']).textContent="";
        }else if(e.target.value=="Smooth-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info3']).textContent="";
        }else {
            ReactDOM.findDOMNode(this.refs['leather_info3']).textContent=" ";
        }
        this.setState({
            primaryBeltLeatherType: e.target.value
        })
        this.props.onAddingPrimaryBeltPrice(e.target.value)
    }

    updateSecondaryBeltLeatherType(e) {
        if(e.target.value=="Grainy-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info4']).textContent="";
        }else if(e.target.value=="Smooth-patterned"){
            ReactDOM.findDOMNode(this.refs['leather_info4']).textContent="";
        }else {
            ReactDOM.findDOMNode(this.refs['leather_info4']).textContent=" ";
        }
        this.setState({
            secondaryBeltLeatherType: e.target.value
        })
        this.props.onAddingSecondaryBeltPrice(e.target.value)
    }
    updateKolonTextureType(e) {

        ReactDOM.findDOMNode(this.refs['leather_info5']).textContent="";
        this.setState({
            kolonTextureType: e.target.value
        })
        this.props.onAddingKolonTexturePrice(e.target.value)
    }


    render() {
        return (
            <li className="filter-list leather enabled expanded" style={{display: 'inline-block', width: '100%'}}>
                <div className="w-clearfix w-inline-block filter-link">
                    <div className="step-parent-heading">
                        <div className="step-title">
                            <span className="step-nr">Step 1</span>
                            <span className="step-count">/{this.isStep === true ? "3" : "4"}</span>
                            <br />
                            <span className="step-label leather-msg2 leather-msg2-vt leather-msg3 leather-msg3-vt leather-msg4 leather-msg4-vt leather-msg5 leather-msg5-vt leather-msg6 leather-msg6-vt leather-msg7 leather-msg7-vt leather-msg8 leather-msg8-vt leather-msg9 leather-msg9-vt" style={{}}>Leathers{this.props.bagName === "Longi" ? " & Woven" : ""}:</span>
                        </div>
                        <div className="step-arrows">
                            <div className="step-right" onClick={this.handleRightClick} data-next={3}>
                                <div />
                                <p style={{marginTop: '-2em', textAlign: 'center'}}>Next</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="option-body">
                    {/* Slecting the primary leather type */}
                    {this.notPriLeather !== true ? (
                    <div className="option-group" ref="primary-option-group">
                        <div className="check-mark-content">
                            <div className="check-body">
                                <div className="mark" />
                            </div>
                        </div>
                        <div className="option leather pick-section" style={{clear: 'both'}}>
                            <div className="step-heading">
                                <span className="step-nr">a.a</span>
                                <span className="step-label">Select your primary leather texture:</span>
                            </div>
                            <div className="builder-padding ctrl-container">
                                <select onChange={this.updatePrimaryLeatherType}>
                                    <option hidden disabled selected value> -- select a type -- </option>
                                    { Object.keys(this.state.textures).map((keyName, i) => (
                                        <option key={i}>{keyName}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        <div className="option leather pick-texture" style={{clear: 'both'}}>
                            <div className="builder-padding step-heading">
                                <span className="step-nr">a.b</span><span className="step-label">Select your primary leather colour:</span><br></br>
                                <span ref="leather_info" className="step-label"></span><br></br>
                                <span className="step-label"> </span><br></br>
                            </div>
                            <ul>
                                {this.state.textures[this.state.primaryLeatherType].map((item, key) => (
                                    <li
                                        key={key}
                                        style={{
                                            backgroundImage: `url('assets/textures/${this.state.primaryLeatherType}/${item}.jpg')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80px 80px',
                                            backgroundPosition: 'center',
                                            border: '1px solid #736751',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            display: 'block',
                                            float: 'left',
                                            height: '42px',
                                            marginRight: '15px',
                                            marginBottom: '15px',
                                            position: 'relative',
                                            transition: 'all 0.4s ease 0s',
                                            width: '42px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.emitTextureOneChangeEvent(this.state.primaryLeatherType, item)
                                        }}
                                    >
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    ) : null}
                    {/* Selecting the secondary leather type */}
                    {this.notSecLeather !== true ? (
                    <div className="option-group" ref="secondary-option-group">
                        <div className="check-mark-content">
                            <div className="check-body">
                                <div className="mark" />
                            </div>
                        </div>
                        <div className="option leather pick-section" style={{clear: 'both'}}>
                            <div className="step-heading">
                                <span className="step-nr">b.a</span>
                                <span className="step-label">Select your secondary leather texture:</span>
                            </div>
                            <div className="builder-padding ctrl-container">
                                <select onChange={this.updateSecondryLeatherType}>
                                    <option hidden disabled selected value> -- select a type -- </option>
                                    { Object.keys(this.state.textures).map((keyName, i) => (
                                        <option key={i}>{keyName}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        <div className="option leather pick-texture" style={{clear: 'both'}}>
                            <div className="builder-padding step-heading">
                                <span className="step-nr">b.b</span><span className="step-label">Select your secondary leather colour:</span><br></br>
                                <span ref="leather_info2" className="step-label"></span><br></br>
                                <span className="step-label"> </span><br></br>
                            </div>
                            <ul>
                                {this.state.textures[this.state.secondryLeatherType].map((item, key) => (
                                    <li
                                        key={key}
                                        style={{
                                            backgroundImage: `url('assets/textures/${this.state.secondryLeatherType}/${item}.jpg')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80px 80px',
                                            backgroundPosition: 'center',
                                            border: '1px solid #736751',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            display: 'block',
                                            float: 'left',
                                            height: '42px',
                                            marginRight: '15px',
                                            marginBottom: '15px',
                                            position: 'relative',
                                            transition: 'all 0.4s ease 0s',
                                            width: '42px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.emitTextureTwoChangeEvent(this.state.secondryLeatherType, item)
                                        }}
                                    >
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    ) : null}
                    <div className="option-group" ref="primary-belt-option-group">
                        <div className="check-mark-content">
                            <div className="check-body">
                                <div className="mark" />
                            </div>
                        </div>
                        {/* Selecting the primary belt leather type */}
                        {this.notPriBelt !== true ? (
                        <div className="option leather pick-section" style={{clear: 'both'}}>
                            <div className="step-heading">
                                <span className="step-nr">{this.props.bagName === "Catcher" ? "b" : "c"}.a</span>
                                <span className="step-label">Select your {this.props.bagName === "Catcher" ? "strap" : "third"} leather texture:</span>
                            </div>
                            <div className="builder-padding ctrl-container">
                                <select onChange={this.updatePrimaryBeltLeatherType}>
                                    <option hidden disabled selected value> -- select a type -- </option>
                                    { Object.keys(this.state.textures).map((keyName, i) => (
                                        <option key={i}>{keyName}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        ) : null}
                        {this.notPriBelt !== true ? (
                        <div className="option leather pick-texture" style={{clear: 'both'}}>
                            <div className="builder-padding step-heading">
                                <span className="step-nr">{this.props.bagName === "Catcher" ? "b" : "c"}.b</span><span className="step-label">Select your {this.props.bagName === "Catcher" ? "strap" : "third"} leather colour:</span><br></br>
                                <span ref="leather_info3" className="step-label"></span><br></br>
                                <span className="step-label"> </span><br></br>
                            </div>
                            <ul>
                                {this.state.textures[this.state.primaryBeltLeatherType].map((item, key) => (
                                    <li
                                        key={key}
                                        style={{
                                            backgroundImage: `url('assets/textures/${this.state.primaryBeltLeatherType}/${item}.jpg')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80px 80px',
                                            backgroundPosition: 'center',
                                            border: '1px solid #736751',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            display: 'block',
                                            float: 'left',
                                            height: '42px',
                                            marginRight: '15px',
                                            marginBottom: '15px',
                                            position: 'relative',
                                            transition: 'all 0.4s ease 0s',
                                            width: '42px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.emitPrimaryBeltChangeEvent(this.state.primaryBeltLeatherType, item)
                                        }}
                                    >
                                    </li>
                                ))}
                            </ul>
                        </div>
                        ) : null}
                    </div>
                    <div className="option-group" ref="secondary-belt-option-group">
                        <div className="check-mark-content">
                            <div className="check-body">
                                <div className="mark" />
                            </div>
                        </div>
                        {/* Selecting the secondary belt leather type */}
                        {this.notSecBelt !== true ? (
                        <div className="option leather pick-section" style={{clear: 'both'}}>
                            <div className="step-heading">
                                <span className="step-nr">{this.props.bagName === "Catcher" ? "b" : "d"}.a</span>
                                <span className="step-label">Select your {this.props.bagName === "Catcher" ? "strap" : "fourth"} leather texture:</span>
                            </div>
                            <div className="builder-padding ctrl-container">
                                <select onChange={this.updateSecondaryBeltLeatherType}>
                                    <option hidden disabled selected value> -- select a type -- </option>
                                    { Object.keys(this.state.textures).map((keyName, i) => (
                                        <option key={i}>{keyName}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        ) : null}
                        {this.notSecBelt !== true ? (
                        <div className="option leather pick-texture" style={{clear: 'both'}}>
                            <div className="builder-padding step-heading">
                                <span className="step-nr">{this.props.bagName === "Catcher" ? "b" : "d"}.b</span><span className="step-label">Select your {this.props.bagName === "Catcher" ? "strap" : "fourth"} leather colour:</span><br></br>
                                <span ref="leather_info4" className="step-label"></span><br></br>
                                <span className="step-label"> </span><br></br>
                            </div>
                            <ul>
                                {this.state.textures[this.state.secondaryBeltLeatherType].map((item, key) => (
                                    <li
                                        key={key}
                                        style={{
                                            backgroundImage: `url('assets/textures/${this.state.secondaryBeltLeatherType}/${item}.jpg')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80px 80px',
                                            backgroundPosition: 'center',
                                            border: '1px solid #736751',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            display: 'block',
                                            float: 'left',
                                            height: '42px',
                                            marginRight: '15px',
                                            marginBottom: '15px',
                                            position: 'relative',
                                            transition: 'all 0.4s ease 0s',
                                            width: '42px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.emitSecondaryBeltChangeEvent(this.state.secondaryBeltLeatherType, item)
                                        }}
                                    >
                                    </li>
                                ))}
                            </ul>
                        </div>
                        ) : null}
                    </div>
                    <div className="option-group" ref="kolon-option-group">
                        <div className="check-mark-content">
                            <div className="check-body">
                                <div className="mark" />
                            </div>
                        </div>
                        {/* Selecting the KOLON texture type */}
                        {this.notKolon !== false ? (
                        <div className="option leather pick-section" style={{clear: 'both'}}>
                            <div className="step-heading">
                                <span className="step-nr">c.a</span>
                                <span className="step-label">Select your Woven texture:</span>
                            </div>
                            <div className="builder-padding ctrl-container">
                                <select onChange={this.updateKolonTextureType}>
                                    <option hidden disabled selected value> -- select a type -- </option>
                                    { Object.keys(this.state.texturesKolon).map((keyName, i) => (
                                        <option key={i}>{keyName}</option>
                                    )) }
                                </select>
                            </div>
                        </div>
                        ) : null}
                        {this.notKolon !== false ? (
                        <div className="option leather pick-texture" style={{clear: 'both'}}>
                            <div className="builder-padding step-heading">
                                <span className="step-nr">c.b</span><span className="step-label">Select your Woven colour:</span><br></br>
                                <span ref="leather_info5" className="step-label"></span><br></br>
                                <span className="step-label"> </span><br></br>
                            </div>
                            <ul>
                                {this.state.texturesKolon[this.state.kolonTextureType].map((item, key) => (
                                    <li
                                        key={key}
                                        style={{
                                            backgroundImage: `url('assets/textures/${this.state.kolonTextureType}/${item}.jpg')`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '80px 80px',
                                            backgroundPosition: 'center',
                                            border: '1px solid #736751',
                                            borderRadius: '50px',
                                            cursor: 'pointer',
                                            display: 'block',
                                            float: 'left',
                                            height: '42px',
                                            marginRight: '15px',
                                            marginBottom: '15px',
                                            position: 'relative',
                                            transition: 'all 0.4s ease 0s',
                                            width: '42px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.emitKolonTextureChangeEvent(this.state.kolonTextureType, item)
                                        }}
                                    >
                                    </li>
                                ))}
                            </ul>
                        </div>
                        ) : null}
                    </div>
                </div>
            </li>
        )
    }
}

export default FirstStep

// Import our dependancies
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import {
	Scene,
	Engine,
	AssetsManager,
	ArcRotateCamera,
	Vector3,
	HemisphericLight,
	Mesh,
	Color3,
	Tools,
	MeshBuilder,
	Texture,
	StandardMaterial,
	Axis,
	DynamicTexture,
	VertexBuffer,
	VertexData
} from "babylonjs";

// Here we extend Reacts component class
class VertiTrundler extends Component {
	// Config Model
		//monogram deri
		monogramMeshIDs = [
			"3d069b0b-5f24-4ab4-85d2-9adfc375fec3", //MONOGRAM
		];

		//Birincil deri tipi
		primaryLeatherMeshIDs = [
			//"3d069b0b-5f24-4ab4-85d2-9adfc375fec3", //MONOGRAM
			"35a3df3d-ed31-4aeb-bb80-67b59f964136",
		];

		//İkincil Deri Tipi
		secondryLeatherMeshIDs = [
			"de15fd5d-1657-4a20-a6c5-9b79eb2dd672",
			"4b60623c-446e-4c2f-a683-3fb8b4461890",
			"8ea2989b-8f5c-4549-ae31-cfcfe85c20fe",
			"ea746b04-7293-452a-8503-78937002ea17"
		];
		//İç Deri
		interiorMeshIDs = [
			//"5ab7ad3a-6faa-430c-8f53-8bb0f957ff73", //fermuar kumas
			"94fb8eca-aefe-4fd7-9d4f-8c81d5ba11f3",
    		"c94cd2b8-e57d-4513-82a5-ee780b29bdd3",
    		"29627080-511e-4310-9cb1-f0f72ffe96d6",
    		"eec05e61-c831-491f-ae8e-3873fcf8ae7f",
    		"90c58395-69b4-4ffc-a8ae-c33d19c03ae7",
    		"4092790e-05fe-4e3b-afe8-b4840e4e9195",
			//"2edddd30-f5bf-4318-9493-3c7dce5d55dd", //iç fermuar kumas
		];

		//Metal IDler
		metalMeshIDs = [
			"1a492b06-322c-49ac-bdcc-308f8aa703c9",
			"fa7688f4-c795-4395-a214-38e371ce348a",
			"671cec12-301e-40ca-8af4-c86a0bb44364",
			"bbcb1de7-a214-43ac-87f5-6ac28a7ed2fc",
			"f4ae3ed5-a3eb-40dc-83d2-f8d13477c8e1",
			"94edb90f-647a-4a4c-b5c5-59411d2f0250",
			"56473a50-87ba-425b-924e-542cfe6fc52d",
			"ca72593e-230d-485d-93a7-993743bcb7f3",
		];

		//Kemerin Dışı
		primaryBeltMeshID = [
			"16fd1967-1957-497c-8d88-ee47e9872903",
			"cbaa3f96-0197-44cc-9708-2e2e5bcb25bb",
			"5e673696-f144-45d1-8b7e-3c3c0737a306",
			"dfd62d8e-fbfa-45a3-8e92-cf02cb103f17",
			"ee2cacaf-4938-4f64-8142-ec38984d565e"
		];
		//Kemerin İçi
		secondaryBeltMeshID = [
			"d19d4d8a-3a53-47d0-8ec1-69d1fe8d62a7"
		];

	config = {
		name : 'Verti Trundler',
		product_id : 9614,//WordPress Ürün ID
		price: 445,//WordPress Ürün Euro Fiyatı
		variation_id: 9617, //WordPress Ürün Varyasyon ID
		model_file : 'verti-trundler.babylon',
		primary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0
		},
		secondary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0.
		},
		primary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		secondary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		interior : {
			u_scale: 0.50,
			v_scale: 0.50
		},
		monogramFragment : {
			u_scale: 4,
			v_scale: 1,
			u_offset: -1.6,
			v_offset: -0.95
		},
		//Monogram Alanın ID si
		text : {
			mesh_id: '3d069b0b-5f24-4ab4-85d2-9adfc375fec3',
			font: 'bold 600px mymonospace',
			scale: 1,
			width: 512 * 6,
			height: 512 * 6,
			left: null,
			top: null
		},
		camera : { // Original Camera
			alpha: -70,
			beta: 70,
			radius: 8,
			upper_limit: 30,
			lower_limit: 2,
			vector_x: 0,
			vector_y: 3,
			vector_z: 0,
			speed: 3.754,
			wheel: 0.01
		},
		interior_camera : { // When select lining goes to cam position
			alpha: 0,
			beta: 2.6,
			radius: 3,
		},
		monogram_camera : { // monogram camera
			alpha: 90,
			beta: 90,
			radius: 2,
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			monogram: "",
			textureType: "Smooth-patterned",
			texture: "Smooth-Black",
			metal: "gunmetal"
		};
		// We bind our events to keep the proper "this" context.
		this.changeTextureOne = this.changeTextureOne.bind(this);
		this.changeTextureTwo = this.changeTextureTwo.bind(this);
		this.changeText = this.changeText.bind(this);
		this.loadModels = this.loadModels.bind(this);
	}

	changeTextureOne = e => {
		/*this.setState({
			textureType: e.detail.textureType,
			texture: e.detail.texture
		});*/

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['primary'].u_scale;
		material1.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		material1.diffuseTexture.uOffset = this.config['primary'].u_offset;
		material1.diffuseTexture.vOffset = this.config['primary'].v_offset;

		// Clones the materials that needs to get changed
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});

		// Keep the text when changing tha texture again
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		//innerGround.scaling.scale(this.config['text'].scale);
		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			"white",
			null,
			true,
			true
		);
		//textureGround.wrapV = 1;

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	changeTextureTwo = e => {
		// Adding new Texture material
		var texture1 = new StandardMaterial("texture1", this.scene);
		texture1.specularColor = new Color3(0.25, 0.25, 0.25);
		texture1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture1.diffuseTexture.uScale = this.config['secondary'].u_scale;
		texture1.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		texture1.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		texture1.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture1;
		});
	};

	changeBeltOne = e => {
		var material3 = new StandardMaterial("belt1", this.scene);
		material3.specularColor = new Color3(0.25, 0.25, 0.25);
		material3.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material3.diffuseTexture.uScale = this.config['primary_belt'].u_scale;
		material3.diffuseTexture.vScale = this.config['primary_belt'].v_scale;

		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material3;
		});
	};

	changeBeltTwo = e => {
		var material4 = new StandardMaterial("belt1", this.scene);
		material4.specularColor = new Color3(0.25, 0.25, 0.25);
		material4.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material4.diffuseTexture.uScale = this.config['secondary_belt'].u_scale;
		material4.diffuseTexture.vScale = this.config['secondary_belt'].v_scale;

		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material4;
		});
	};

	changeMetal = e => {
		var material2 = new StandardMaterial("metal1", this.scene);
		material2.diffuseTexture = new Texture(
			`assets/metals/${e.detail.metal}.jpg`,
			this.scene
		);

		// Clones the materials that needs to get changed
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material2;
		});
	};

	changeInterior = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['interior_camera'].radius,
			alpha: this.config['interior_camera'].alpha,
			beta: this.config['interior_camera'].beta,
			ease: Power2.easeOut
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/linings/${e.detail.interior}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['interior'].u_scale;
		material1.diffuseTexture.vScale = this.config['interior'].v_scale;

		// Clones the materials that needs to get changed
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});
	};

	changeText = e => {
		this.setState(
			{
				monogram: e.detail.text
			},
			() => {
				// Adding Text
				var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
				var innerGround = ground.clone("innerGround");
				//innerGround.scaling.scale(this.config['text'].scale);
				//Create dynamic texture
				var textureResolution = 512;
				var textureGround = new DynamicTexture(
					"dynamic texture",
					{ width: this.config['text'].width, height: this.config['text'].height },
					this.scene
				);
				textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
				textureGround.hasAlpha = true;

				var font = this.config['text'].font;
				textureGround.drawText(
					this.state.monogram,
					this.config['text'].left,
					this.config['text'].top,
					font,
					"white",
					null,
					true,
					true
				);
				//textureGround.wrapV = 1;

				var materialGround = new StandardMaterial("Mat", this.scene);
				materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
				materialGround.diffuseTexture = textureGround;

				// Scale texture
				materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
				materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

				// Offset texture
				materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
				materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

				var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
				innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

				innerGroundMaterial.diffuseTexture = new Texture(
					`transparentbg.png`,
					this.scene
				);

				// Scale texture
				innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
				innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

				// Offset texture
				innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
				innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

				//transparent texture alpha conf
				materialGround.diffuseTexture.hasAlpha  =true;
				innerGroundMaterial.diffuseTexture.hasAlpha=true;
				materialGround.useAlphaFromDiffuseTexture = true;
				innerGroundMaterial.useAlphaFromDiffuseTexture = true;

				ground.material = materialGround;
				innerGround.material = innerGroundMaterial;
			}
		);
	};

	changeTextColor = e => {
		// Adding Text
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		innerGround.scaling.scale(this.config['text'].scale);

		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;
		var textureContext = textureGround.getContext();

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			e.detail.color,
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	// Makes the canvas behave responsively
	onResizeWindow = () => {
		if (this.engine) {
			this.engine.resize();
		}
	};

	// Sets up our canvas tag for webGL scene
	setEngine = () => {
		this.stage.style.width = "200%";
		this.stage.style.height = "200%";
		this.engine = new Engine(this.stage);
		this.stage.style.width = "100%";
		this.stage.style.height = "100%";
	};

	// Creates the scene graph
	setScene = () => {
		this.scene = new Scene(this.engine);

		// Background
		this.scene.clearColor = new Color3(255, 255, 255);
	};

	// Adds camera to our scene.
	setCamera = () => {
		this.camera = new ArcRotateCamera(
			"Camera",
			Tools.ToRadians(this.config['camera'].alpha),
			Tools.ToRadians(this.config['camera'].beta),
			this.config['camera'].radius,
			new Vector3(this.config['camera'].vector_x, this.config['camera'].vector_y, this.config['camera'].vector_z),
			this.scene
		);
		this.camera.attachControl(this.stage, false);
		this.camera.lowerRadiusLimit = this.config['camera'].lower_limit;
		this.camera.upperRadiusLimit = this.config['camera'].upper_limit;
		this.camera.wheelDeltaPercentage = this.config['camera'].wheel;
		this.camera.speed = this.config['camera'].speed;
	};

	moveCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['camera'].radius,
			alpha: Tools.ToRadians(this.config['camera'].alpha),
			beta: Tools.ToRadians(this.config['camera'].beta),
			ease: Power2.easeOut
		});
	};

	moveMonogramCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['monogram_camera'].radius,
			alpha: Tools.ToRadians(this.config['monogram_camera'].alpha),
			beta: Tools.ToRadians(this.config['monogram_camera'].beta),
			ease: Power2.easeOut
		});
	};

	loadModels = () => {
		let loader = new AssetsManager(this.scene);
		// Arguments: "ID", "Root URL", "URL Prefix", "Filename"
		let loadBagModel = loader.addMeshTask(
			"bonabag",
			"",
			"",
			this.config['model_file']
		);

		/*
			Loader is given a callback to run when the model has loaded
			the variable t is our imported scene. You can use
			it to examine all the mesh's loaded.
		*/

		loadBagModel.onSuccess = t => {
			// Adding new Texture material
			var texture_def = new StandardMaterial("texture_def", this.scene);
			texture_def.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def.diffuseTexture.uScale = this.config['primary'].u_scale;
			texture_def.diffuseTexture.vScale = this.config['primary'].v_scale;

			// Offset texture
			texture_def.diffuseTexture.uOffset = this.config['primary'].u_offset;
			texture_def.diffuseTexture.vOffset = this.config['primary'].v_offset;

			let meshIDs = this.primaryLeatherMeshIDs.concat(
				this.secondryLeatherMeshIDs,
				this.interiorMeshIDs,
				this.primaryBeltMeshID,
				this.secondaryBeltMeshID
			);

			// Clones the materials that needs to get changed
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def;
			});

			// Metal Components Default Texture
			var metal_def = new StandardMaterial("metal1", this.scene);
			metal_def.diffuseTexture = new Texture(
				`assets/metals/${this.state.metal}.jpg`,
				this.scene
			);

			// Clones the materials that needs to get changed
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = metal_def;
			});

			// Monogram Mesh Default Texture
			var monogram_def = new StandardMaterial("mono1", this.scene);
			monogram_def.diffuseTexture = new Texture(
				`transparentbg.png`,
				this.scene
			);

			monogram_def.diffuseTexture.hasAlpha  =true;

			// Clones the materials that needs to get changed
			this.monogramMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.monogramMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = monogram_def;
			});

			// Start the animation loop once the model is loaded
			this.engine.runRenderLoop(() => {
				this.scene.render();
			});

			// The model came in a little dark so lets add some extra light
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(-1, -1, 0.5), this.scene);
		};

		// It also calls an Error callback if something goes wrong
		loadBagModel.onError = function(task, message, exception) {
			console.log(message, exception);
		};

		if (this.engine) {
			this.engine.resize();
		}

		// We return the fully configured loader
		return loader;
	};

	//Build the scene when the component has been loaded.
	componentDidMount() {
		// Change the displayed bag name
		this.props.onNameChange(this.config['name'], this.config['price'], this.config['product_id'],this.config['variation_id']);

		this.setEngine();
		this.setScene();
		this.setCamera();
		this.loadModels().load();

		// We can add our custom events just like any other DOM event
		window.addEventListener("resize", this.onResizeWindow);
		window.addEventListener("move-camera", this.moveCamera);
		window.addEventListener("move-monogram-camera", this.moveMonogramCamera);
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
		window.addEventListener("change-belt-two", this.changeBeltTwo);
		window.addEventListener("change-metal", this.changeMetal);
		window.addEventListener("change-interior", this.changeInterior);
		window.addEventListener("change-text", this.changeText);
		window.addEventListener("change-text-color", this.changeTextColor);
	}
	//Renderes our Canvas tag and saves a reference to it.
	render() {
		return <canvas className="scene" ref={el => (this.stage = el)}></canvas>;
	}
}

//returns the scene to be used by other components
export default VertiTrundler;

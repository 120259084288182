// Import our dependancies
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import {
	Scene,
	Engine,
	AssetsManager,
	ArcRotateCamera,
	Vector3,
	HemisphericLight,
	Mesh,
	Color3,
	Tools,
	MeshBuilder,
	Texture,
	StandardMaterial,
	Axis,
	DynamicTexture,
	VertexBuffer,
	VertexData
} from "babylonjs";

// Here we extend Reacts component class
class DualHarness extends Component {
	// Config Model

		//monogram deri
		monogramMeshIDs = [
			"Monogram"
		];

		//Birincil deri tipi
		primaryLeatherMeshIDs = [
			"KalinAlan"
		];

		//İkincil Deri Tipi
		secondryLeatherMeshIDs = [
			"DarAlan",
			"Govde",
			"Govde2",
		];
		//İç Deri
		interiorMeshIDs = [
		];

		//Metal IDler
		metalMeshIDs = [
			"Metal",
			"BonabagLogo"
		];

		//Kemerin Dışı
		primaryBeltMeshID = [
		];
		//Kemerin İçi
		secondaryBeltMeshID = [
		];

	config = {
		name : 'Dual Harness',
		product_id : 10452,//WordPress Ürün ID
		price: 127.5,//WordPress Ürün Euro Fiyatı
		variation_id: 10453, //WordPress Ürün Varyasyon ID
		model_file : 'dual-harness.babylon',
		primary : {
			u_scale: 3,
			v_scale: 3,
			u_offset: 0,
			v_offset: 0
		},
		secondary : {
			u_scale: 4,
			v_scale: 4,
			u_offset: 0,
			v_offset: 0
		},
		primary_belt : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0
		},
		secondary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		interior : {
			u_scale: 4,
			v_scale: 4
		},
		monogramFragment : {
			u_scale: 2.5,
			v_scale: 1,
			u_offset: -0.5,//-0.75,
			v_offset: 0
		},
		//Monogram Alanın ID si
		text : {
			mesh_id: 'Monogram',
			font: 'bold 170px mymonospace',
			scale: 1,
			width: 512 *2,
			height: 512 *2,
			left: null,
			top: null
		},
		camera : { // Original Camera
			alpha: 90,
			beta: 40,
			radius: 150,
			upper_limit: 300,
			lower_limit: 50,
			vector_x: 0,
			vector_y: 1,
			vector_z: 0,
			speed: 3.754,
			wheel: 0.01
		},
		interior_camera : { // When select lining goes to cam position
			alpha: 0,
			beta: 0,
			radius: 1,
		},
		monogram_camera : { // monogram camera
			alpha: -20,
			beta: 80,
			radius: 75,
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			monogram: "",
			textureType: "Smooth-patterned",
			texture: "Smooth-Black",
			metal: "gunmetal"
		};
		// We bind our events to keep the proper "this" context.
		this.changeTextureOne = this.changeTextureOne.bind(this);
		this.changeTextureTwo = this.changeTextureTwo.bind(this);
		this.changeText = this.changeText.bind(this);
		this.loadModels = this.loadModels.bind(this);
	}

	changeTextureOne = e => {

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['primary'].u_scale;
		material1.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		material1.diffuseTexture.uOffset = this.config['primary'].u_offset;
		material1.diffuseTexture.vOffset = this.config['primary'].v_offset;

		// Clones the materials that needs to get changed
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});

       //material1.backFaceCulling = false


	};

	changeTextureTwo = e => {
		this.setState({
			textureType: e.detail.textureType,
			texture: e.detail.texture
		});
		// Adding new Texture material
		var texture1 = new StandardMaterial("texture1", this.scene);
		texture1.specularColor = new Color3(0.25, 0.25, 0.25);
		texture1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture1.diffuseTexture.uScale = this.config['secondary'].u_scale;
		texture1.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		texture1.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		texture1.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture1;
		});

        //texture1.backFaceCulling = false

		// Keep the text when changing tha texture again
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		//innerGround.scaling.scale(this.config['text'].scale);
		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			"white",
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

        //material3.backFaceCulling = false

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	changeBeltOne = e => {

		var material3 = new StandardMaterial("belt1", this.scene);
		material3.specularColor = new Color3(0.25, 0.25, 0.25);
		material3.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material3.diffuseTexture.uScale = this.config['primary_belt'].u_scale;
		material3.diffuseTexture.vScale = this.config['primary_belt'].v_scale;

		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material3;
		});

	};

	changeBeltTwo = e => {
		var material4 = new StandardMaterial("belt1", this.scene);
		material4.specularColor = new Color3(0.25, 0.25, 0.25);
		material4.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material4.diffuseTexture.uScale = this.config['secondary_belt'].u_scale;
		material4.diffuseTexture.vScale = this.config['secondary_belt'].v_scale;

		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material4;
		});
	};

	changeMetal = e => {
		var material2 = new StandardMaterial("metal1", this.scene);

		material2.diffuseTexture = new Texture(
			`assets/metals/${e.detail.metal}.jpg`,
			this.scene
		);

		material2.diffuseTexture.level = 1.5


		// Clones the materials that needs to get changed
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material2;

		});
	};

	changeInterior = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['interior_camera'].radius,
			alpha: this.config['interior_camera'].alpha,
			beta: this.config['interior_camera'].beta,
			ease: Power2.easeOut
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/linings/${e.detail.interior}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['interior'].u_scale;
		material1.diffuseTexture.vScale = this.config['interior'].v_scale;

		// Clones the materials that needs to get changed
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});

        material1.backFaceCulling = false
	};

	changeText = e => {
		this.setState(
			{
				monogram: e.detail.text
			},
			() => {
				// Adding Text
				var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
				var innerGround = ground.clone("innerGround");
				//innerGround.scaling.scale(this.config['text'].scale);
				//Create dynamic texture
				var textureResolution = 512;
				var textureGround = new DynamicTexture(
					"dynamic texture",
					{ width: this.config['text'].width, height: this.config['text'].height },
					this.scene
				);
				textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
				textureGround.hasAlpha = true;

				var font = this.config['text'].font;
				textureGround.drawText(
					this.state.monogram,
					this.config['text'].left,
					this.config['text'].top,
					font,
					"white",
					null,
					true,
					true
				);
				//textureGround.wrapV = 1;

				var materialGround = new StandardMaterial("Mat", this.scene);
				materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
				materialGround.diffuseTexture = textureGround;

				// Scale texture
				materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
				materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

				// Offset texture
				materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
				materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

				var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
				innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

				innerGroundMaterial.diffuseTexture = new Texture(
					`transparentbg.png`,
					this.scene
				);

				// Scale texture
				innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
				innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

				// Offset texture
				innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
				innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

				//transparent texture alpha conf
				materialGround.diffuseTexture.hasAlpha  =true;
				innerGroundMaterial.diffuseTexture.hasAlpha=true;
				materialGround.useAlphaFromDiffuseTexture = true;
				innerGroundMaterial.useAlphaFromDiffuseTexture = true;

                /*materialGround.backFaceCulling = false;
				innerGroundMaterial.backFaceCulling = false;*/

                //textureGround.backFaceCulling = false

				ground.material = materialGround;
				innerGround.material = innerGroundMaterial;
			}
		);
	};

	changeTextColor = e => {
		// Adding Text
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		innerGround.scaling.scale(this.config['text'].scale);

		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;
		var textureContext = textureGround.getContext();

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			e.detail.color,
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['monogramFragment'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['monogramFragment'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['monogramFragment'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['monogramFragment'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	// Makes the canvas behave responsively
	onResizeWindow = () => {
		if (this.engine) {
			this.engine.resize();
		}
	};

	// Sets up our canvas tag for webGL scene
	setEngine = () => {
		this.stage.style.width = "200%";
		this.stage.style.height = "200%";
		this.engine = new Engine(this.stage);
		this.stage.style.width = "100%";
		this.stage.style.height = "100%";
	};

	// Creates the scene graph
	setScene = () => {
		this.scene = new Scene(this.engine);

		// Background
		this.scene.clearColor = new Color3(255, 255, 255);
	};

	// Adds camera to our scene.
	setCamera = () => {
		this.camera = new ArcRotateCamera(
			"Camera",
			Tools.ToRadians(this.config['camera'].alpha),
			Tools.ToRadians(this.config['camera'].beta),
			this.config['camera'].radius,
			new Vector3(this.config['camera'].vector_x, this.config['camera'].vector_y, this.config['camera'].vector_z),
			this.scene
		);
		this.camera.attachControl(this.stage, false);
		this.camera.lowerRadiusLimit = this.config['camera'].lower_limit;
		this.camera.upperRadiusLimit = this.config['camera'].upper_limit;
		this.camera.wheelDeltaPercentage = this.config['camera'].wheel;
		this.camera.speed = this.config['camera'].speed;
	};

	moveCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['camera'].radius,
			alpha: Tools.ToRadians(this.config['camera'].alpha),
			beta: Tools.ToRadians(this.config['camera'].beta),
			ease: Power2.easeOut
		});
	};

	moveMonogramCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['monogram_camera'].radius,
			alpha: Tools.ToRadians(this.config['monogram_camera'].alpha),
			beta: Tools.ToRadians(this.config['monogram_camera'].beta),
			ease: Power2.easeOut
		});
	};

	loadModels = () => {
		let loader = new AssetsManager(this.scene);
		// Arguments: "ID", "Root URL", "URL Prefix", "Filename"
		let loadBagModel = loader.addMeshTask(
			"bonabag",
			"",
			"",
			this.config['model_file']
		);

		/*
			Loader is given a callback to run when the model has loaded
			the variable t is our imported scene. You can use
			it to examine all the mesh's loaded.
		*/

		loadBagModel.onSuccess = t => {
			// Adding new Texture material
			var texture_def = new StandardMaterial("texture_def", this.scene);
			texture_def.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def.diffuseTexture.uScale = this.config['primary'].u_scale;
			texture_def.diffuseTexture.vScale = this.config['primary'].v_scale;

			// Offset texture
			texture_def.diffuseTexture.uOffset = this.config['primary'].u_offset;
			texture_def.diffuseTexture.vOffset = this.config['primary'].v_offset;

           texture_def.backFaceCulling = false

			let meshIDs = this.primaryLeatherMeshIDs.concat(
				this.secondryLeatherMeshIDs,
				this.interiorMeshIDs,
				this.primaryBeltMeshID,
				this.secondaryBeltMeshID,
				this.config['text'].mesh_id
			);

			// Clones the materials that needs to get changed
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def;
			});

			// Metal Components Default Texture
			var metal_def = new StandardMaterial("metal1", this.scene);
			metal_def.diffuseTexture = new Texture(
				`assets/metals/${this.state.metal}.jpg`,
				this.scene
			);
			metal_def.diffuseTexture.level = 1.5

			// Clones the materials that needs to get changed
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = metal_def;
			});

			// Monogram Mesh Default Texture
			var monogram_def = new StandardMaterial("mono1", this.scene);
			monogram_def.diffuseTexture = new Texture(
				`transparentbg.png`,
				this.scene
			);

			monogram_def.diffuseTexture.hasAlpha  =true;

			// Clones the materials that needs to get changed
			this.monogramMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.monogramMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = monogram_def;
			});

			// Start the animation loop once the model is loaded
			this.engine.runRenderLoop(() => {
				this.scene.render();
			});

			// The model came in a little dark so lets add some extra light
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(-1, -1, 0.5), this.scene);
		};

		// It also calls an Error callback if something goes wrong
		loadBagModel.onError = function(task, message, exception) {
			console.log(message, exception);
		};

		if (this.engine) {
			this.engine.resize();
		}

		// We return the fully configured loader
		return loader;
	};

	//Build the scene when the component has been loaded.
	componentDidMount() {
		// Change the displayed bag name
		this.props.onNameChange(this.config['name'], this.config['price'], this.config['product_id'], this.config['variation_id']);

		this.setEngine();
		this.setScene();
		this.setCamera();
		this.loadModels().load();

		// We can add our custom events just like any other DOM event
		window.addEventListener("resize", this.onResizeWindow);
		window.addEventListener("move-camera", this.moveCamera);
		window.addEventListener("move-monogram-camera", this.moveMonogramCamera);
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
		window.addEventListener("change-belt-two", this.changeBeltTwo);
		window.addEventListener("change-metal", this.changeMetal);
		window.addEventListener("change-interior", this.changeInterior);
		window.addEventListener("change-text", this.changeText);
		window.addEventListener("change-text-color", this.changeTextColor);
	}
	//Renderes our Canvas tag and saves a reference to it.
	render() {
		return <canvas className="scene" ref={el => (this.stage = el)}></canvas>;
	}
}

//returns the scene to be used by other components
export default DualHarness;

// src/Components/Controls.js
import ReactDOM from 'react-dom';
import React, { Component } from "react";

class FourthStep extends Component {


	textColors = [
		"white",
		"black",
		"darkgray",
		"silver",
		"gold",
		"red",
		"firebrick",
		"magenta",
		"rebeccapurple",
		"limegreen",
		"darkolivegreen",
		"deepskyblue",
		"midnightblue"
	];
	isStep;
	constructor(props) {


		super(props);
		//3 step olanlar sayı düzenleme
		 var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		 this.isStep = bags.includes(this.props.bagName)


		 var limitBags1 = ['Chain Leash','Dual Chain Leash','Longi']
		 var chLimit1 = limitBags1.includes(this.props.bagName)

		if (chLimit1) {
			var charactersLeftDefault = 1;
		}else {
			var charactersLeftDefault = 8;
		}

		var monoinput="";

		var icons = ['┤','╣','║','╗','╝','┐','└','┴','┬','├','─','┼','╚','╔','╩','╦','╠','═','╬','┘','┌','█','▄','▀','■','░','▒','▓','²','⌂','³','¹','¶','•','○','◙','§','▬','↨','↑','↓','→','←','∟','↔','▲','▼','ø','½','Ø','®','¬','«','»','│','æ','Æ','î','ò','►','◄','↕','é','ä','à','å','ë','ï','Ä','ê','â','è','Å','É','ô','û','ù','£','ê'];

		this.state = {
			charactersLeftDefault: charactersLeftDefault,
			charactersLeft: charactersLeftDefault,
			monoinput : monoinput,
			icons: icons
		};
		this.handleLeftClick = this.handleLeftClick.bind(this);
		this.handleRightClick = this.handleRightClick.bind(this);
		this.emitTextChangeEvent = this.emitTextChangeEvent.bind(this);

		//var regexValue ="";

		var bagsCustom = ['Maxi Dualer Fuchsia','Midi Dualer Lavatera','Mini Dualer Sunset','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isCustomModel = bagsCustom.includes(this.props.bagName)
		if(isCustomModel){
			let event;
			event = new CustomEvent("move-monogram-camera", {});
			window.dispatchEvent(event);
		}


	}

	handleLeftClick() {
		this.props.onStepLeft();
		let event = new CustomEvent("move-camera", {});
		window.dispatchEvent(event);
	}

	handleRightClick() {
		this.props.onStepRight();
	}

	emitTextChangeEvent(e) {

		ReactDOM.findDOMNode(this.refs['text-option-group']).classList.add('checked');

		let text = e.target.value;
		let event = new CustomEvent("change-text", {
			detail: {
				text
			}
		});
		window.dispatchEvent(event);

		if (text.length <= this.state.charactersLeftDefault) {
			this.setState({
				charactersLeft: this.state.charactersLeftDefault - text.length,
			});
		}

		//todo
       this.state.monoinput = text


		/*ReactDOM.findDOMNode(this.refs['text-option-group']).classList.add('checked');
		//todo
		var inputValue = e.target.value;
		var rgx = /^[A-Za-z]*$/; // only eng
		if (!rgx.test(inputValue))
		{
			return false;
		}
		else{
			let text =  inputValue  // .replace(/^[A-Za-z]*$/, ""); eng & number
			let event = new CustomEvent("change-text", {
			detail: {
				text
				}
			});
			window.dispatchEvent(event);

			if (text.length <= this.state.charactersLeftDefault) {
				this.setState({
					charactersLeft : this.state.charactersLeftDefault - text.length,
				});
				this.regexValue = text
			}
		}*/
	}

	emitTextColorChangeEvent(color) {
		let event = new CustomEvent("change-text-color", {
			detail: {
				color
			}
		});
		window.dispatchEvent(event);
	}

	buildOptions() {

		const styleMonogramList = {
			fontFamily: 'mymonospace',
			listStyleType: 'none',
			padding:'0',
			lineHeight:'45px',
			display:'inline-block',
			marginBottom:'10px'
		}

		const styleMonogramLi = {
			fontFamily: 'mymonospace',
			float:'left',
			marginRight:'15px',
			fontSize:'2.4rem',
			color: '#000'
		}

		return (
			<ul style={styleMonogramList}>
			  {this.state.icons.map(name => (
				<li style={styleMonogramLi}>
				  <a class="my-icon" onClick={() => this.updateText(name)}>{name}</a>
				</li>
			  ))}

			</ul>
		  );

    }

	updateText(e) {

		ReactDOM.findDOMNode(this.refs['text-option-group']).classList.add('checked');

		let text = e;

		if(this.state.monoinput.length < this.state.charactersLeftDefault){

			/*
			this.setState({
				monoinput: `${this.state.monoinput}${text}`
			});
			*/

			this.state.monoinput += text

			let event = new CustomEvent("change-text", {
				detail: {
					text : `${this.state.monoinput}` //${text}
					}
				});
			window.dispatchEvent(event);

			this.setState({
				charactersLeft: this.state.charactersLeftDefault - this.state.monoinput.length,
			});
		}
    }



	render() {
		const styleMonogramInput = {
			fontFamily: 'mymonospace',
			marginTop:'2em'
		}

		return (
			<li
				className="filter-list monogram text enabled expanded"
				style={{ display: "inline-block", width: "100%" }}
			>
				<div className="w-clearfix w-inline-block filter-link">
					<div className="step-parent-heading">
						<div className="step-title">
							<span className="step-nr">Step {this.isStep === true ? "3" : "4"}</span>
							<span className="step-count">/{this.isStep === true ? "3" : "4"}</span>
							<br />
							<span className="step-label">Monograms:</span>
						</div>
						<div className="step-arrows">
							<div
								className="step-left"
								onClick={this.handleLeftClick}
								data-previous={1}
							>
								<div />
								<p style={{marginTop: '-2em', textAlign: 'center'}}>Back</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="option monogram text text-colour show-incl-dots">
	                    <div className="option-group" ref="text-option-group">
	                        <div className="check-mark-content">
	                            <div className="check-body">
	                                <div className="mark" />
	                            </div>
	                        </div>
							<div className="builder-padding monogram-creator">

							    {this.buildOptions()}

							   <hr/>

								<input
									type="text"
									className="input-text validate-length product-custom-option"
									maxLength={this.state.charactersLeftDefault}
									onChange={this.emitTextChangeEvent}
									style={styleMonogramInput}
									value={this.state.monoinput}
								/>

								<div
									className="mono-options text-type-options"
									style={{ display: "none" }}
								>
									Select Your Monogramming Option:
								</div>
								<div
									className="text-type option-radio"
									style={{ display: "none" }}
								/>
								<div
									className="mono-options mono-font-options"
									style={{ display: "none" }}
								>
									Select Your Monogram Font Type:
								</div>
								<div className="monogram-font" style={{ display: "none" }} />
								<div
									className="mono-options mono-font-options-printed"
									style={{ display: "none" }}
								>
									<div className="control">
										<span
											tabIndex={0}
											id
											role="combobox"
											aria-expanded="false"
											aria-autocomplete="list"
											aria-owns
											aria-haspopup="true"
											title
											className="ui-selectmenu-button ui-button ui-widget ui-selectmenu-button-closed ui-corner-all"
											aria-activedescendant="ui-id-4"
											aria-labelledby="ui-id-2"
											aria-disabled="false"
										>
											<span className="ui-selectmenu-icon ui-icon ui-icon-triangle-1-s" />
											<span className="ui-selectmenu-text">
												Please Select Font
											</span>
										</span>
										<div
											className="ui-selectmenu-menu ui-front ui-selectmenu-open"
											style={{}}
										>
											<ul
												className="monogram-font-printed ui-menu ui-corner-bottom ui-widget ui-widget-content"
												style={{ display: "none" }}
											>
												<li
													className="container-swatch-image swatch-option2743 swatch-value12657 sku-silver swatch-selected"
													style={{ display: "list-item" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Silver Hot Stamp</div>
												</li>
												<li
													className="container-swatch-image swatch-option2743 swatch-value12658 sku-gunmetal swatch"
													style={{ display: "none" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Gunmetal Hot Stamp</div>
												</li>
												<li
													className="container-swatch-image swatch-option2743 swatch-value12659 sku-gold swatch"
													style={{ display: "none" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Gold Hot Stamp</div>
												</li>
												<li
													className="container-swatch-image swatch-option2743 swatch-value12660 sku-rosegold swatch"
													style={{ display: "none" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Rose Gold Hot Stamp</div>
												</li>
												<li
													className="container-swatch-image swatch-option2743 swatch-value68715 sku-redhs swatch"
													style={{ display: "list-item" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Red Hot Stamp</div>
												</li>
												<li
													className="container-swatch-image swatch-option2743 swatch-value12661 sku-emboss swatch"
													style={{ display: "list-item" }}
												>
													<div className="swatch-image">&nbsp;</div>
													<div className="swatch-title">Blind Emboss</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div
									className="mono-options mono-size-options"
									style={{ display: "none" }}
								>
									Select Monogram Font Size:
								</div>
								<div
									className="monogram-size option-radio"
									style={{ display: "none" }}
								/>
								<div>
									<p className="chars-remaining">
										Characters left:{" "}
										<strong id="monogram_text_remaining">
											{this.state.charactersLeft}
										</strong>
									</p>
								</div>
								{/*<div class="builcer-padding mono-size-options" style="display:none;"><p class="fine-print">Symbols are only available for large type size.</p></div> */}
								<div className="mono-options">
									Select your monogram colour:{" "}
									<span className="mono-option-current mono-color-current" />
								</div>
								<div className="monogram-colour-select">
									<ul>
										{this.textColors.map((item, key) => (
											<li
												key={key}
												style={{
													backgroundColor: item,
													backgroundRepeat: "no-repeat",
													backgroundSize: "80px 80px",
													backgroundPosition: "center",
													border: "1px solid #736751",
													borderRadius: "50px",
													cursor: "pointer",
													display: "block",
													float: "left",
													height: "42px",
													marginRight: "15px",
													marginBottom: "15px",
													position: "relative",
													transition: "all 0.4s ease 0s",
													width: "42px"
												}}
												onClick={e => {
													e.preventDefault();
													this.emitTextColorChangeEvent(item);
												}}
											></li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</li>
		);
	}
}

export default FourthStep;

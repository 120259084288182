// Import our dependancies
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import {
	Scene,
	Engine,
	AssetsManager,
	ArcRotateCamera,
	Vector3,
	HemisphericLight,
	Mesh,
	Color3,
	Tools,
	MeshBuilder,
	Texture,
	StandardMaterial,
	Axis,
	DynamicTexture,
	VertexBuffer,
	VertexData
} from "babylonjs";

// Here we extend Reacts component class
class UrbanCase extends Component {
	// Config Model

	//Birincil deri tipi
	primaryLeatherMeshIDs = [
		"6be9677e-c5c9-4331-ab42-c58fade0591f", //ön deri
		"1ceb940e-5d0b-4c05-8640-4f220201fa36", //arka deri //monogram
		"6da9be32-29a8-4124-8560-64199792ea7e", //iç parça1
		"f3d29218-55dc-49ae-bd21-fa3a7cdce73f",//iç parça2

		"e8f352bd-652d-40c8-a346-c0b6bf1e4af8",
		"9e5f5aff-2870-46b8-8317-0adddbe77bfc",

		"07ae0075-a090-4d62-b352-f0711524f708",
		"82c31b30-d5df-4010-89de-60f650a38910",
	];
	//İkincil Deri Tipi
	secondryLeatherMeshIDs = [


		"25451ea7-f432-45cc-8e9c-3875e4050b1d", // ön lazerli cep

	];


	//İç Deri
    interiorMeshIDs = [
		"65099b8d-4c44-422a-bf76-f97ed78118bc",
		"ba014a0c-10a6-4073-a770-a27bb9bcaa96",
		"51fbf8da-c069-4a9e-84ba-777e51717a14",
	];

	//Metal IDler
	metalMeshIDs = [
		"1ef02dae-99c3-49fc-bd60-d6e7b81b5dfe",
		"8708de2e-25e7-495c-ac6b-e89c2fbb6a77",
		"e0b096db-c164-4c6d-aaa9-d493813e53f4",
		"fc8ca807-5ce5-49ea-9aef-45faac1e9a83",
		"1bec6a9d-ab11-495b-a272-d249de02c686",
		"261d6e02-1c0c-4b8e-acd6-b034ff579c21",
		"d446e69f-92e0-4b3d-9efa-0014276218c0",
		"2bc87f70-0cab-47fb-b63d-240eafc8d0cb",
		"cf4f4f44-2b9c-4744-b3da-63e09b867f62",
		"14c74674-6af1-4759-b0a1-1ca5724b8a22",
		"e8fb9f68-d8b5-421b-bf93-78bf6c7e2eb0",
		"2a4fda06-7a8e-4c81-8dbf-ad69ff82748e",
		"65516980-86e6-41b3-ac5f-b1e566befe46",
		"2943e2b1-57d8-4ca7-b2c6-d3943f1a0ca4",
	];

	//Kemerin Dışı
	primaryBeltMeshID = [
		//"a0c9ddbe-c931-4427-ac23-e341c2f460b7", //kemer deri degil
		"5bebd90e-4c59-48d6-84ee-fe34bf682330", // kücük sap
		"f2e1e16e-d4f2-4327-93f9-18fbe65c4a18", // kücük sap
		"82812e22-f294-4864-83f4-8014d24ca100", // el sapi
		"f09f2440-2c1d-45d5-b190-508e73419eb8", //el sapi

	];
	//Kemerin İçi
	secondaryBeltMeshID = [

	];

	config = {
		name : 'Urban Case',
		product_id : 9605,//WordPress Ürün ID
        price: 345,//WordPress Ürün Euro Fiyatı
		variation_id : 9607,//WordPress Ürün Varyasyon ID
		model_file : 'urban-case-unisex.babylon',
		primary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0
		},
		secondary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0.5
		},
		primary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		secondary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		interior : {
			u_scale: 0.50,
			v_scale: 0.50
		},
		//Monogram Alanın ID si
		text : {
			mesh_id: '1ceb940e-5d0b-4c05-8640-4f220201fa36',
			font: 'bold 750px mymonospace',
			scale: 1,
			width: 512 * 9,
			height: 512 * 9,
			left: null,
			top: null
		},
		camera : { // Original Camera
			alpha: -60,
			beta: 70,
			radius: 1200,
			upper_limit: 1800,
			lower_limit: 50,
			vector_x: 0,
			vector_y: 74,
			vector_z: 0,
			speed: 3.754,
			wheel: 0.01
		},
		interior_camera : { // When select lining goes to cam position
			alpha: 0,
			beta: 1.3,
			radius: 170,
		},
		monogram_camera : { // monogram camera
			alpha: 90,
			beta: 126,
			radius: 620,
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			monogram: "",
			textureType: "Smooth-patterned",
			texture: "Smooth-Black",
			metal: "matte_black"
		};
		// We bind our events to keep the proper "this" context.
		this.changeTextureOne = this.changeTextureOne.bind(this);
		this.changeTextureTwo = this.changeTextureTwo.bind(this);
		this.changeText = this.changeText.bind(this);
		this.loadModels = this.loadModels.bind(this);
	}

	changeTextureOne = e => {
		this.setState({
			textureType: e.detail.textureType,
			texture: e.detail.texture
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['primary'].u_scale;
		material1.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		material1.diffuseTexture.uOffset = this.config['primary'].u_offset;
		material1.diffuseTexture.vOffset = this.config['primary'].v_offset;

		// Clones the materials that needs to get changed
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});

		// Keep the text when changing tha texture again
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		//innerGround.scaling.scale(this.config['text'].scale);
		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			"white",
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	changeTextureTwo = e => {


		// Adding new Texture material
		var texture1 = new StandardMaterial("texture1", this.scene);
		texture1.specularColor = new Color3(0.25, 0.25, 0.25);
		texture1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture1.diffuseTexture.uScale = this.config['secondary'].u_scale;
		texture1.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		texture1.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		texture1.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture1;
		});


	};

	changeBeltOne = e => {
		var material3 = new StandardMaterial("belt1", this.scene);
		material3.specularColor = new Color3(0.25, 0.25, 0.25);
		material3.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material3.diffuseTexture.uScale = this.config['primary_belt'].u_scale;
		material3.diffuseTexture.vScale = this.config['primary_belt'].v_scale;

		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material3;
		});
	};

	changeBeltTwo = e => {
		var material4 = new StandardMaterial("belt1", this.scene);
		material4.specularColor = new Color3(0.25, 0.25, 0.25);
		material4.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material4.diffuseTexture.uScale = this.config['secondary_belt'].u_scale;
		material4.diffuseTexture.vScale = this.config['secondary_belt'].v_scale;

		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material4;
		});
	};

	changeMetal = e => {
		var material2 = new StandardMaterial("metal1", this.scene);
		material2.diffuseTexture = new Texture(
			`assets/metals/${e.detail.metal}.jpg`,
			this.scene
		);

		// Clones the materials that needs to get changed
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material2;
		});
	};

	changeInterior = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['interior_camera'].radius,
			alpha: this.config['interior_camera'].alpha,
			beta: this.config['interior_camera'].beta,
			ease: Power2.easeOut
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/linings/${e.detail.interior}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['interior'].u_scale;
		material1.diffuseTexture.vScale = this.config['interior'].v_scale;

		// Clones the materials that needs to get changed
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});
	};

	changeText = e => {
		this.setState(
			{
				monogram: e.detail.text
			},
			() => {
				// Adding Text
				var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
				var innerGround = ground.clone("innerGround");
				innerGround.scaling.scale(this.config['text'].scale);

				//Create dynamic texture
				var textureResolution = 512;
				var textureGround = new DynamicTexture(
					"dynamic texture",
					{ width: this.config['text'].width, height: this.config['text'].height },
					this.scene
				);
				textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
				textureGround.hasAlpha = true;
				var textureContext = textureGround.getContext();

				var font = this.config['text'].font;
				textureGround.drawText(
					this.state.monogram,
					this.config['text'].left,
					this.config['text'].top,
					font,
					"white",
					null,
					true,
					true
				);
				//textureGround.wrapV = 1;

				var materialGround = new StandardMaterial("Mat", this.scene);
				materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
				materialGround.emissiveTexture = textureGround;
				materialGround.diffuseTexture = textureGround;

				// Scale texture
				materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
				materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

				// Offset texture
				materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
				materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

				var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
				innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

				innerGroundMaterial.diffuseTexture = new Texture(
					`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
					this.scene
				);

				// Scale texture
				innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
				innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

				// Offset texture
				innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
				innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

				ground.material = materialGround;
				innerGround.material = innerGroundMaterial;
			}
		);
	};

	changeTextColor = e => {
		// Adding Text
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		innerGround.scaling.scale(this.config['text'].scale);

		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;
		var textureContext = textureGround.getContext();

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			e.detail.color,
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['secondary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['secondary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	// Makes the canvas behave responsively
	onResizeWindow = () => {
		if (this.engine) {
			this.engine.resize();
		}
	};

	// Sets up our canvas tag for webGL scene
	setEngine = () => {
		this.stage.style.width = "200%";
		this.stage.style.height = "200%";
		this.engine = new Engine(this.stage);
		this.stage.style.width = "100%";
		this.stage.style.height = "100%";
	};

	// Creates the scene graph
	setScene = () => {
		this.scene = new Scene(this.engine);

		// Background
		this.scene.clearColor = new Color3(255, 255, 255);
	};

	// Adds camera to our scene.
	setCamera = () => {
		this.camera = new ArcRotateCamera(
			"Camera",
			Tools.ToRadians(this.config['camera'].alpha),
			Tools.ToRadians(this.config['camera'].beta),
			this.config['camera'].radius,
			new Vector3(this.config['camera'].vector_x, this.config['camera'].vector_y, this.config['camera'].vector_z),
			this.scene
		);
		this.camera.attachControl(this.stage, false);
		this.camera.lowerRadiusLimit = this.config['camera'].lower_limit;
		this.camera.upperRadiusLimit = this.config['camera'].upper_limit;
		this.camera.wheelDeltaPercentage = this.config['camera'].wheel;
		this.camera.speed = this.config['camera'].speed;
	};

	moveCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['camera'].radius,
			alpha: Tools.ToRadians(this.config['camera'].alpha),
			beta: Tools.ToRadians(this.config['camera'].beta),
			ease: Power2.easeOut
		});
	};

	moveMonogramCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['monogram_camera'].radius,
			alpha: Tools.ToRadians(this.config['monogram_camera'].alpha),
			beta: Tools.ToRadians(this.config['monogram_camera'].beta),
			ease: Power2.easeOut
		});
	};

	loadModels = () => {
		let loader = new AssetsManager(this.scene);
		// Arguments: "ID", "Root URL", "URL Prefix", "Filename"
		let loadBagModel = loader.addMeshTask(
			"bonabag",
			"",
			"",
			this.config['model_file']
		);

		/*
			Loader is given a callback to run when the model has loaded
			the variable t is our imported scene. You can use
			it to examine all the mesh's loaded.
		*/

		loadBagModel.onSuccess = t => {
			// Adding new Texture material
			var texture_def = new StandardMaterial("texture_def", this.scene);
			texture_def.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def.diffuseTexture.uScale = this.config['secondary'].u_scale;
			texture_def.diffuseTexture.vScale = this.config['secondary'].v_scale;

			// Offset texture
			texture_def.diffuseTexture.uOffset = this.config['secondary'].u_offset;
			texture_def.diffuseTexture.vOffset = this.config['secondary'].v_offset;

			let meshIDs = this.primaryLeatherMeshIDs.concat(
				this.secondryLeatherMeshIDs,
				this.interiorMeshIDs,
				this.primaryBeltMeshID,
				this.secondaryBeltMeshID
			);

			// Clones the materials that needs to get changed
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def;
			});

			// Metal Components Default Texture
			var metal_def = new StandardMaterial("metal1", this.scene);
			metal_def.diffuseTexture = new Texture(
				`assets/metals/${this.state.metal}.jpg`,
				this.scene
			);

			// Clones the materials that needs to get changed
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = metal_def;
			});

			// Start the animation loop once the model is loaded
			this.engine.runRenderLoop(() => {
				this.scene.render();
			});

			// The model came in a little dark so lets add some extra light
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(-1, -1, 0.5), this.scene);
		};

		// It also calls an Error callback if something goes wrong
		loadBagModel.onError = function(task, message, exception) {
			console.log(message, exception);
		};

		if (this.engine) {
			this.engine.resize();
		}

		// We return the fully configured loader
		return loader;
	};

	//Build the scene when the component has been loaded.
	componentDidMount() {
		// Change the displayed bag name
		this.props.onNameChange(this.config['name'], this.config['price'], this.config['product_id'],this.config['variation_id']);

		this.setEngine();
		this.setScene();
		this.setCamera();
		this.loadModels().load();

		// We can add our custom events just like any other DOM event
		window.addEventListener("resize", this.onResizeWindow);
		window.addEventListener("move-camera", this.moveCamera);
		window.addEventListener("move-monogram-camera", this.moveMonogramCamera);
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
		window.addEventListener("change-belt-two", this.changeBeltTwo);
		window.addEventListener("change-metal", this.changeMetal);
		window.addEventListener("change-interior", this.changeInterior);
		window.addEventListener("change-text", this.changeText);
		window.addEventListener("change-text-color", this.changeTextColor);
	}
	//Renderes our Canvas tag and saves a reference to it.
	render() {
		return <canvas className="scene" ref={el => (this.stage = el)}></canvas>;
	}
}

//returns the scene to be used by other components
export default UrbanCase;
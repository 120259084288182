// src/Components/Controls.js
import ReactDOM from 'react-dom';
import React, { Component } from "react";

class SecondStep extends Component {

	isStep;
	constructor(props) {
		super(props);
		this.handleLeftClick = this.handleLeftClick.bind(this);
		this.handleRightClick = this.handleRightClick.bind(this);
		if(this.props.bagName === "Dreamer" || this.props.bagName === "Urban Case" ){
			this.state = {
				metals: ["matte_black"]
			};
		}
		else{
			this.state = {
				metals: ["matte_gold", "matte_silver", "silver", "gunmetal"]
			};
		}
		//3 step olanlar sayı düzenleme
		var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)'];
		this.isStep = bags.includes(this.props.bagName)
	}

	handleLeftClick() {
		this.props.onStepLeft();
		let event = new CustomEvent("move-camera", {});
		window.dispatchEvent(event);
	}

	handleRightClick() {
		this.props.onStepRight();
		let event;
		//3 step olanlar monogram-cameraya geçsin
		var bags = ['Urban Leash','Urban Collar','Chain Leash','Dual Leash','Dual Chain Leash','Dual Collar','Dual Harness','Dual Harness Candy (M Size)','Dual Harness Sky (M Size)']
		var isRight = bags.includes(this.props.bagName)

		//sadece iç alanda metal olan
		var bagsInterior = ['Maxi Dualer','Maxi Dualer Fuchsia']
		var isInterior = bagsInterior.includes(this.props.bagName)

		if (isRight) {
			event = new CustomEvent("move-monogram-camera", {});
	   }else if(isInterior){
			event = new CustomEvent("move-interior-camera", {});
	   }
	   else{
			event = new CustomEvent("move-camera", {});
	   }
	   window.dispatchEvent(event);
	}

	emitMetalChangeEvent(metal) {
        ReactDOM.findDOMNode(this.refs['metal-option-group']).classList.add('checked');
		let event = new CustomEvent("change-metal", {
			detail: {
				metal
			}
		});
		window.dispatchEvent(event);
	}

	render() {
		return (
			<li
				className="filter-list hardware enabled expanded"
				style={{ display: "inline-block", width: "100%" }}
			>
				<div className="w-clearfix w-inline-block filter-link">
					<div className="step-parent-heading">
						<div className="step-title">
							<span className="step-nr">Step 2</span>
							<span className="step-count">/{this.isStep === true ? "3" : "4"}</span>
							<br />
							<span className="step-label">Hardwares:</span>
						</div>
						<div className="step-arrows">
							<div
								className="step-left"
								onClick={this.handleLeftClick}
								data-previous={1}
							>
								<div />
								<p style={{marginTop: '-2em', textAlign: 'center'}}>Back</p>
							</div>
							<div
								className="step-right"
								onClick={this.handleRightClick}
								data-next={3}
							>
								<div />
								<p style={{marginTop: '-2em', textAlign: 'center'}}>Next</p>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div className="option hardware colour">
	                    <div className="option-group" ref="metal-option-group">
	                        <div className="check-mark-content">
	                            <div className="check-body">
	                                <div className="mark" />
	                            </div>
	                        </div>
							<div className="builder-padding step-heading">
								<span className="step-label">Select your hardware colour: </span>
							</div>
							<div className="builder-padding ctrl-container">
								<ul>
									{this.state.metals.map((item, key) => (
										<li
											key={key}
											style={{
												backgroundImage: `url('assets/metals/${item}.jpg')`,
												backgroundRepeat: "no-repeat",
												backgroundSize: "80px 80px",
												backgroundPosition: "center",
												border: "1px solid #736751",
												borderRadius: "50px",
												cursor: "pointer",
												display: "block",
												float: "left",
												height: "42px",
												marginRight: "15px",
												marginBottom: "15px",
												position: "relative",
												transition: "all 0.4s ease 0s",
												width: "42px"
											}}
											onClick={e => {
												e.preventDefault();
												this.emitMetalChangeEvent(item);
											}}
										></li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</li>
		);
	}
}

export default SecondStep;

// Import our dependancies
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import {
	Scene,
	Engine,
	AssetsManager,
	ArcRotateCamera,
	Vector3,
	HemisphericLight,
	Mesh,
	Color3,
	Tools,
	MeshBuilder,
	Texture,
	StandardMaterial,
	Axis,
	DynamicTexture,
	VertexBuffer,
	VertexData
} from "babylonjs";

// Here we extend Reacts component class
class MacroPucker extends Component {
	// Config Model

		//Birincil deri tipi
		primaryLeatherMeshIDs = [
			"51eac1a6-95ec-4339-9a0d-6b9f890b4bfe",
			"59c91cc5-8be0-495f-8310-7e9e5f707e36",
			"5dccb966-9fba-4376-b3c2-6632b58f8c1a",//MONOGRAM
			"41504936-867e-4864-b01c-e56f61f7a675",
			"6d499abd-8eaf-43b6-afe5-ecae34c723bb"
		];

		//İkincil Deri Tipi
		secondryLeatherMeshIDs = [
			"74b048e0-5c5a-4eeb-8c5c-ef61ec7d41bb",
			"f676a83a-0734-4b9c-a1e4-c1b652d7c828"
		];
		//İç Deri
		interiorMeshIDs = [
			"a7cbba08-6efb-4f06-897a-b4ed220898df",
			"8275d29a-62e3-40a5-b3e3-365ff9dfee43",
			"1aa4f26f-7ac9-4300-ba66-3ec7090425ff",
			"0f5fa2c0-7bf9-4665-b355-499502fa02d7"
		];

		//Metal IDler
		metalMeshIDs = [
		"e32dbd64-bc49-42dc-811e-2fe1ae0cff95",
		"4d49fc4f-7627-4bdf-851f-626f84d2ffd1",
		"c84a1c04-49af-483a-806b-7b280bd46a51",
		"4d269359-9a1d-41f9-90d7-6ea35ba41cb1",
		"33ddb3e7-d4a7-4e07-a5e1-995a9dfb8f58",
		"332a0cb7-c9c4-4983-b51e-809059a61211",
		"bbd98f8b-11d7-489a-b957-9e87c8a14b62",
		"9a04bf1a-a387-4435-8feb-aad1284ada4f",
		"c0a15970-9822-4c22-8df0-1aab986f49dd",
		"55657f4d-3db1-43dd-98f8-9ac4f755fccb",
		"06f4ab1e-a3ad-4054-910f-879b5130cd75",
		"6f5e1b44-2946-46b0-954d-0b3e0466b580",
		"56c67c59-e6ca-41a0-b45b-8ebc384e651b",
		"b23afcad-4061-41fa-83b1-864aaa1dd8a2",
		"a988b75b-ecd9-4462-ba14-f56131e25097",
		"f099be50-3abb-418f-bf56-df3ef3bc9f7e",
		"46650e01-0999-424f-943c-157e3b15f418",
		"1430b932-3310-4be1-817c-fedf6327c2d6",
		"5015305c-ad6e-444f-8391-d9753ff8df01",
		"dc69ccb7-7d6b-40bc-8451-8240f14effae",
		"df645fad-02d4-43c7-9655-bca3511b9c95",
		"0a975d30-a880-42fe-b6e3-b5d7073b073c",
		"d8dcb81d-054f-4130-955a-fd53437e8b5b",
		"4fcc79f0-3902-4b2d-8bf1-914d54f05a47",
		"d670cf02-9a17-4e93-bad6-abf911879e92",
		"a9ec9e16-6313-4b9d-bfcc-cd1765048f43",
		"1891ef03-09aa-490e-b999-f58dc8f156b1",
		"e7c2d691-75ef-4e94-a1c8-2204c59c38e4",
		"b29ed169-4023-4e54-82da-fecae52343fe",
		"46c7270d-5a7a-47b1-ac63-ad77bc2de9f4",
		"eebde9d0-f1cc-4622-932c-6b49701a902c",
		"ba7cd021-78e8-463f-bf9f-ea285adf495e",
		"290e3642-801a-4a59-8a59-e99eda94ddcf",
		"36e4c534-ccfc-4cb1-92d9-5eb67c3775b1",
		"bdbf0394-60d8-4d0c-a4af-8d3748cf993c",
		"e3d06d55-b1fc-4d78-9f31-5804099c5f98",
		"59ab4b29-7dd1-452e-abc8-0c98d19679ad",
		"564287ba-2b6f-456f-81b6-fd8e70274594",
		"f7c69dea-a719-4c59-aef4-2d144b5a8e35",
		"9c6e79c7-5fc1-44a5-8278-0fce88ce08c8",
		"87c48130-668e-407c-a806-bcdb593d8286",
		"a0366a9d-a40e-4080-a24c-3c852267ebd7",
		"f88e823b-9760-47c4-b83e-ec67091d406e",
		"c919649a-59e5-4632-97e3-708dc7f9a06a",
		"48b17d0e-5428-4525-a879-72f1eb23d71c",
		"0762adb9-7134-4b52-99cd-2d0bf8021572",
		"01c845b2-a9e2-4251-a080-5c2e050934b1",
		"b2ad9232-cca0-4523-8dd4-8bc5419bba61",
		"117b9bc8-5650-4772-a582-dc8799bce835",
		"56be91e9-a94f-4e32-9bf5-b489c2731d43",
		"4cc92a7b-a20c-4b11-9de4-13e19bad3b55",
		"4f458124-3b4c-4659-84a8-775ef5d90306",
		"6053affb-a6ca-43d8-952c-f2f4d391a5d6",
		"87cedaad-62ee-4dab-a7b3-9f21f37a2a93",
		"4c7412a9-68c3-4212-9bf5-c1699112e9ab",
		"dff232e3-068f-4eca-bc76-5bcd112a0166",
		"db8a63d3-ec5f-4324-b598-f701a9bffd24",
		"93cafe7c-4831-4ab8-8216-4163999ff24f",
		"a8a8f562-b610-4f87-8a2f-1be76d23f21b",
		"44d11eb5-fcf0-4d35-a0a9-fffb93b64231",
		"6a34df5d-3474-41e8-8da8-a0c4ffc05860",
		"793c1b01-9e5e-4cd4-a153-b526b36ce91a",
		"024573df-836d-49c6-9bea-3215994c5e5f",
		"944e8fdc-2cdd-4920-bc8d-81e216f4bff9",
		"d832a8a5-a608-4cef-87df-424a7b31e86a",
		"0acb7516-e2fd-465c-95c9-fbbe9c2a0b95",
		"e08deb2e-9f42-4f35-90eb-1ec680fd1c1b",
		"7e900c4a-7120-488c-8279-ba3e95971828",
		"2165ffd4-c2b7-4e58-b924-cd04cfd7c797",
		"27d910b3-6257-4ef5-af60-5eb5f5152937",
		"096fd991-8706-41f2-8e0e-b01dabfee742",
		"5e421f07-9833-4d13-a4b7-cb1a2c9107da",
		"787cf672-a913-49cf-9a96-86fd1886931f",
		"971f2bce-62bb-4b1a-beb8-dbab12704adb",
		"2238cad5-eddd-418b-9cf6-cd0d6132d969",
		"6a8e5e98-c650-4a99-aa7d-94fbce37cb7b",
		"d33a895e-82a1-4451-bf98-53d7b35a7dcb",
		"b6c641e7-7f40-4896-8b12-cc826340d980",
		"054f8b83-1b52-4e11-b1dd-5b9430857939",
		"f1344258-f5cd-49dc-b4c5-79774df7d99e",
		"963ec693-b528-49de-95cf-5335274be580",
		"c9c7965f-b2cc-4db1-9fa0-bac326570021",
		"322517e5-44fa-4e89-b999-2dfe67148900",
		"4e63e87e-ce94-4ff6-b0dd-da1f0e778b4e",
		"63449e11-4a71-4653-9c47-4dbde721f782",
		"52001735-b59c-4889-b7fc-5cdfe841c855",
		"b565e6e5-f444-435d-8ecf-b6163ac1b570",
		"398a3936-54e1-4e2d-8502-a493c1df1546",
		"97c4d522-70d4-4183-84bb-8cb204d3d086",
		"7922ae25-12fe-44ea-90dd-93d5c737b86b",
		"389dc805-2c5b-4960-8182-427cb8a00125",
		"1a6bea2e-29dd-4f44-b302-0b40e45f490b",
		"5f0e4f40-9ba4-4eeb-9510-1073133086e9",
		"2adaccae-6592-4051-bc89-dedaae77c8ec",
		"3a4abe38-9fb9-48b8-aa94-cdeb8a51ac04",
		"0fc39e3c-0387-457c-9d9f-6bd308cd2eec",
		"a1561567-7ced-4f99-ba83-1b9e112fa961",
		"0474849a-7d33-44bd-a90f-6c037d0ced5a",
		"9ca4533d-46e9-4cec-9d4a-27f49e047dad",
		"07289fb4-856f-4e09-a893-6266a819f7f9",
		"dc90de5e-b4e0-41d6-a8c5-2a895fb968e7",
		"8ddf5be6-dba7-4b00-b803-0d2e6730e5bb",
		"d4094883-fd67-4477-a19e-bffc9c0e06d3",
		"eb85b1a2-7a8b-48ab-9f71-aca4f50b023e",
		"58933eb5-e2fb-4632-94c8-3fe58a4ada8c",
		"e0815690-bfba-4b83-82ba-db2513249a95",
		"93b633a0-6b8b-4a74-a086-f79e4c6ef96d",
		"082e2c4e-644f-418d-aded-b5a7ffdc1560",
		"f0f02106-5cd4-406c-b509-3826698cb4d3",
		"837db331-9462-4279-8ad5-868127da4f95",
		"3ff1660d-f469-48b5-b25c-08b76f23035b",
		"47b90a11-8879-4b92-a5c6-222a246ef047",
		"7dad9cd8-cbd1-46ed-a8b5-7884f001d429",
		"6587ce14-3e69-47a0-a18a-f6120f7a8550",
		"4afafada-2adf-4bfe-87d6-683d3c77fefb",
		"deac2b4c-13c4-4adc-a94b-6422de880ef4",
		"e019236a-20d6-497c-b844-b26caca2b4f9",
		"ccd8f868-400c-4c2b-92b7-e050502143e3",
		"d9897b44-85c7-4ea4-a041-f92c24b4ebe6",
		"de6f4bea-8cce-4f8e-877b-4bfe9f156703",
		"521ee7c3-65ab-4e1b-9e74-3e6306a5905a",
		"83a37c9a-f5b0-4923-b5e6-ea50a3667a9f",
		"6ea90030-ab6b-4300-9fe5-925733d67d95",
		"5fbc60d9-769a-4908-bac1-373423fbcd3a",
		"e94c43cd-cb43-4e83-b6ae-721e75bda123",
		"96be3686-584e-4662-9d08-fe6bc3feeffb",
		"5c48c822-b5f5-4690-800e-2eaa8dac4305",
		"ee3ae0ee-601f-446e-83b7-74506bf5493f",
		"6a4038e4-007a-4bf1-8abb-d38d7660ab5a",
		"4dcf1d10-62a3-4c07-98b4-c3477fec3568",
		"4bb23c03-90da-43a0-af8f-94d40c1b9e68",
		"d4329dc1-17fd-4865-91d8-b36f35ad0d3c",
		"6680fbe7-bd4d-49f6-a7b2-faed23200e8e",
		"3692769b-37e0-4946-bb40-cdfeac30d9a0",
		"615c22c2-6729-46ae-902d-9d0f6ea93367",
		"e9b077ff-5027-4c5f-8258-f1e0fd3dc3d8",
		"1b7afe69-cc8f-4f29-b7d1-fb3af57d9378",
		"d2a7dafa-dd23-4357-9bb3-3d3e924be844",
		"d4553e88-2689-4546-bc55-27316b412fe8",
		"718c8e20-9fe5-4e1e-896a-c191bc8ae763",
		];

		//Kemerin Dışı
		primaryBeltMeshID = [
			"01bb90ba-c544-4a56-9ef2-d26eed4a2bd3"
		];
		//Kemerin İçi
		secondaryBeltMeshID = [
		];

	config = {
		name : 'Macro Pucker',
		product_id : 9621,//WordPress Ürün ID
		price: 545,//WordPress Ürün Euro Fiyatı,
		variation_id: 9623, //WordPress Ürün Varyasyon ID
		model_file : 'macro-pucker.babylon',
		primary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: -0.95 // -1.7
		},
		secondary : {
			u_scale: 1,
			v_scale: 1,
			u_offset: 0,
			v_offset: 0.5
		},
		primary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		secondary_belt : {
			u_scale: 1,
			v_scale: 1
		},
		interior : {
			u_scale: 0.50,
			v_scale: 0.50
		},
		//Monogram Alanın ID si
		text : {
			mesh_id: '5dccb966-9fba-4376-b3c2-6632b58f8c1a',
			font: 'bold 300px mymonospace',
			scale: 1,
			width: 512 * 9,
			height: 512 * 9,
			left: null,
			top: 600
		},
		camera : { // Original Camera
			alpha: -70,
			beta: 70,
			radius: 23,
			upper_limit: 30,
			lower_limit: 5,
			vector_x: 0,
			vector_y: 0,
			vector_z: 0,
			speed: 3.754,
			wheel: 0.01
		},
		interior_camera : { // When select lining goes to cam position
			alpha: 0,
			beta: 1.3,
			radius: 5,
		},
		monogram_camera : { // monogram camera
			alpha: -90,
			beta: 90,
			radius: 8,
		}
	};

	constructor(props) {
		super(props);
		this.state = {
			monogram: "",
			textureType: "Smooth-patterned",
			texture: "Smooth-Black",
			metal: "gunmetal"
		};
		// We bind our events to keep the proper "this" context.
		this.changeTextureOne = this.changeTextureOne.bind(this);
		this.changeTextureTwo = this.changeTextureTwo.bind(this);
		this.changeText = this.changeText.bind(this);
		this.loadModels = this.loadModels.bind(this);
	}

	changeTextureOne = e => {
		/*this.setState({
			textureType: e.detail.textureType,
			texture: e.detail.texture
		});*/

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['primary'].u_scale;
		material1.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		material1.diffuseTexture.uOffset = this.config['primary'].u_offset;
		material1.diffuseTexture.vOffset = this.config['primary'].v_offset;

		// Clones the materials that needs to get changed
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});

		// Keep the text when changing tha texture again
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		//innerGround.scaling.scale(this.config['text'].scale);
		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			"white",
			null,
			true,
			true
		);
		//textureGround.wrapV = 1;

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['primary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['primary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['primary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['primary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['primary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['primary'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	changeTextureTwo = e => {
		// Adding new Texture material
		var texture1 = new StandardMaterial("texture1", this.scene);
		texture1.specularColor = new Color3(0.25, 0.25, 0.25);
		texture1.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		texture1.diffuseTexture.uScale = this.config['secondary'].u_scale;
		texture1.diffuseTexture.vScale = this.config['secondary'].v_scale;

		// Offset texture
		texture1.diffuseTexture.uOffset = this.config['secondary'].u_offset;
		texture1.diffuseTexture.vOffset = this.config['secondary'].v_offset;

		// Clones the materials that needs to get changed
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondryLeatherMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = texture1;
		});
	};

	changeBeltOne = e => {
		var material3 = new StandardMaterial("belt1", this.scene);
		material3.specularColor = new Color3(0.25, 0.25, 0.25);
		material3.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material3.diffuseTexture.uScale = this.config['primary_belt'].u_scale;
		material3.diffuseTexture.vScale = this.config['primary_belt'].v_scale;

		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.primaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material3;
		});
	};

	changeBeltTwo = e => {
		var material4 = new StandardMaterial("belt1", this.scene);
		material4.specularColor = new Color3(0.25, 0.25, 0.25);
		material4.diffuseTexture = new Texture(
			`assets/textures/${e.detail.textureType}/${e.detail.texture}.jpg`,
			this.scene
		);

		// Scale texture
		material4.diffuseTexture.uScale = this.config['secondary_belt'].u_scale;
		material4.diffuseTexture.vScale = this.config['secondary_belt'].v_scale;

		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.secondaryBeltMeshID.map(meshID => {
			this.scene.getMeshByID(meshID).material = material4;
		});
	};

	changeMetal = e => {
		var material2 = new StandardMaterial("metal1", this.scene);
		material2.diffuseTexture = new Texture(
			`assets/metals/${e.detail.metal}.jpg`,
			this.scene
		);

		// Clones the materials that needs to get changed
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.metalMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material2;
		});
	};

	changeInterior = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['interior_camera'].radius,
			alpha: this.config['interior_camera'].alpha,
			beta: this.config['interior_camera'].beta,
			ease: Power2.easeOut
		});

		// Adding new Texture material
		var material1 = new StandardMaterial("texture1", this.scene);
		material1.specularColor = new Color3(0.25, 0.25, 0.25);
		material1.diffuseTexture = new Texture(
			`assets/linings/${e.detail.interior}.jpg`,
			this.scene
		);

		// Scale texture
		material1.diffuseTexture.uScale = this.config['interior'].u_scale;
		material1.diffuseTexture.vScale = this.config['interior'].v_scale;

		// Clones the materials that needs to get changed
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = this.scene
				.getMeshByID(meshID)
				.material.clone();
		});

		// Applying the new texture on the material
		this.interiorMeshIDs.map(meshID => {
			this.scene.getMeshByID(meshID).material = material1;
		});
	};

	changeText = e => {
		this.setState(
			{
				monogram: e.detail.text
			},
			() => {
				// Adding Text
				var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
				var innerGround = ground.clone("innerGround");
				//innerGround.scaling.scale(this.config['text'].scale);
				//Create dynamic texture
				var textureResolution = 512;
				var textureGround = new DynamicTexture(
					"dynamic texture",
					{ width: this.config['text'].width, height: this.config['text'].height },
					this.scene
				);
				textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
				textureGround.hasAlpha = true;

				var font = this.config['text'].font;
				textureGround.drawText(
					this.state.monogram,
					this.config['text'].left,
					this.config['text'].top,
					font,
					"white",
					null,
					true,
					true
				);
				//textureGround.wrapV = 1;

				var materialGround = new StandardMaterial("Mat", this.scene);
				materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
				materialGround.diffuseTexture = textureGround;

				// Scale texture
				materialGround.diffuseTexture.uScale = this.config['primary'].u_scale;
				materialGround.diffuseTexture.vScale = this.config['primary'].v_scale;

				// Offset texture
				materialGround.diffuseTexture.uOffset = this.config['primary'].u_offset;
				materialGround.diffuseTexture.vOffset = this.config['primary'].v_offset;

				var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
				innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

				innerGroundMaterial.diffuseTexture = new Texture(
					`transparentbg.png`,
					this.scene
				);

				// Scale texture
				innerGroundMaterial.diffuseTexture.uScale = this.config['primary'].u_scale;
				innerGroundMaterial.diffuseTexture.vScale = this.config['primary'].v_scale;

				// Offset texture
				innerGroundMaterial.diffuseTexture.uOffset = this.config['primary'].u_offset;
				innerGroundMaterial.diffuseTexture.vOffset = this.config['primary'].v_offset;

				//transparent texture alpha conf
				materialGround.diffuseTexture.hasAlpha  =true;
				innerGroundMaterial.diffuseTexture.hasAlpha=true;
				materialGround.useAlphaFromDiffuseTexture = true;
				innerGroundMaterial.useAlphaFromDiffuseTexture = true;

				ground.material = materialGround;
				innerGround.material = innerGroundMaterial;
			}
		);
	};

	changeTextColor = e => {
		// Adding Text
		var ground = this.scene.getMeshByID(this.config['text'].mesh_id);
		var innerGround = ground.clone("innerGround");
		innerGround.scaling.scale(this.config['text'].scale);

		//Create dynamic texture
		var textureResolution = 512;
		var textureGround = new DynamicTexture(
			"dynamic texture",
			{ width: this.config['text'].width, height: this.config['text'].height },
			this.scene
		);
		textureGround.specularColor = new Color3(0.25, 0.25, 0.25);
		textureGround.hasAlpha = true;
		var textureContext = textureGround.getContext();

		var font = this.config['text'].font;
		textureGround.drawText(
			this.state.monogram,
			this.config['text'].left,
			this.config['text'].top,
			font,
			e.detail.color,
			null,
			true,
			true
		);

		var materialGround = new StandardMaterial("Mat", this.scene);
		materialGround.specularColor = new Color3(0.25, 0.25, 0.25);
		materialGround.diffuseTexture = textureGround;

		// Scale texture
		materialGround.diffuseTexture.uScale = this.config['primary'].u_scale;
		materialGround.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		materialGround.diffuseTexture.uOffset = this.config['primary'].u_offset;
		materialGround.diffuseTexture.vOffset = this.config['primary'].v_offset;

		var innerGroundMaterial = new StandardMaterial("innerGroundMaterial", this.scene);
		innerGroundMaterial.specularColor = new Color3(0.25, 0.25, 0.25);

		innerGroundMaterial.diffuseTexture = new Texture(
			`transparentbg.png`,
			this.scene
		);

		// Scale texture
		innerGroundMaterial.diffuseTexture.uScale = this.config['primary'].u_scale;
		innerGroundMaterial.diffuseTexture.vScale = this.config['primary'].v_scale;

		// Offset texture
		innerGroundMaterial.diffuseTexture.uOffset = this.config['primary'].u_offset;
		innerGroundMaterial.diffuseTexture.vOffset = this.config['primary'].v_offset;

		//transparent texture alpha conf
		materialGround.diffuseTexture.hasAlpha  =true;
		innerGroundMaterial.diffuseTexture.hasAlpha=true;
		materialGround.useAlphaFromDiffuseTexture = true;
		innerGroundMaterial.useAlphaFromDiffuseTexture = true;

		ground.material = materialGround;
		innerGround.material = innerGroundMaterial;
	};

	// Makes the canvas behave responsively
	onResizeWindow = () => {
		if (this.engine) {
			this.engine.resize();
		}
	};

	// Sets up our canvas tag for webGL scene
	setEngine = () => {
		this.stage.style.width = "200%";
		this.stage.style.height = "200%";
		this.engine = new Engine(this.stage);
		this.stage.style.width = "100%";
		this.stage.style.height = "100%";
	};

	// Creates the scene graph
	setScene = () => {
		this.scene = new Scene(this.engine);

		// Background
		this.scene.clearColor = new Color3(255, 255, 255);
	};

	// Adds camera to our scene.
	setCamera = () => {
		this.camera = new ArcRotateCamera(
			"Camera",
			Tools.ToRadians(this.config['camera'].alpha),
			Tools.ToRadians(this.config['camera'].beta),
			this.config['camera'].radius,
			new Vector3(this.config['camera'].vector_x, this.config['camera'].vector_y, this.config['camera'].vector_z),
			this.scene
		);
		this.camera.attachControl(this.stage, false);
		this.camera.lowerRadiusLimit = this.config['camera'].lower_limit;
		this.camera.upperRadiusLimit = this.config['camera'].upper_limit;
		this.camera.wheelDeltaPercentage = this.config['camera'].wheel;
		this.camera.speed = this.config['camera'].speed;
	};

	moveCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['camera'].radius,
			alpha: Tools.ToRadians(this.config['camera'].alpha),
			beta: Tools.ToRadians(this.config['camera'].beta),
			ease: Power2.easeOut
		});
	};

	moveMonogramCamera = e => {
		TweenMax.to(this.camera, 1, {
			radius: this.config['monogram_camera'].radius,
			alpha: Tools.ToRadians(this.config['monogram_camera'].alpha),
			beta: Tools.ToRadians(this.config['monogram_camera'].beta),
			ease: Power2.easeOut
		});
	};

	loadModels = () => {
		let loader = new AssetsManager(this.scene);
		// Arguments: "ID", "Root URL", "URL Prefix", "Filename"
		let loadBagModel = loader.addMeshTask(
			"bonabag",
			"",
			"",
			this.config['model_file']
		);

		/*
			Loader is given a callback to run when the model has loaded
			the variable t is our imported scene. You can use
			it to examine all the mesh's loaded.
		*/

		loadBagModel.onSuccess = t => {
			// Adding new Texture material
			var texture_def = new StandardMaterial("texture_def", this.scene);
			texture_def.specularColor = new Color3(0.25, 0.25, 0.25);
			texture_def.diffuseTexture = new Texture(
				`assets/textures/${this.state.textureType}/${this.state.texture}.jpg`,
				this.scene
			);

			// Scale texture
			texture_def.diffuseTexture.uScale = this.config['primary'].u_scale;
			texture_def.diffuseTexture.vScale = this.config['primary'].v_scale;

			// Offset texture
			texture_def.diffuseTexture.uOffset = this.config['primary'].u_offset;
			texture_def.diffuseTexture.vOffset = this.config['primary'].v_offset;

			let meshIDs = this.primaryLeatherMeshIDs.concat(
				this.secondryLeatherMeshIDs,
				this.interiorMeshIDs,
				this.primaryBeltMeshID,
				this.secondaryBeltMeshID,
				this.config['text'].mesh_id
			);

			// Clones the materials that needs to get changed
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			meshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = texture_def;
			});

			// Metal Components Default Texture
			var metal_def = new StandardMaterial("metal1", this.scene);
			metal_def.diffuseTexture = new Texture(
				`assets/metals/${this.state.metal}.jpg`,
				this.scene
			);

			// Clones the materials that needs to get changed
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = this.scene
					.getMeshByID(meshID)
					.material.clone();
			});

			// Applying the new texture on the material
			this.metalMeshIDs.map(meshID => {
				this.scene.getMeshByID(meshID).material = metal_def;
			});

			// Start the animation loop once the model is loaded
			this.engine.runRenderLoop(() => {
				this.scene.render();
			});

			// The model came in a little dark so lets add some extra light
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(1, 1, 1), this.scene);
			new HemisphericLight("HemiLight", new Vector3(-1, -1, 0.5), this.scene);
		};

		// It also calls an Error callback if something goes wrong
		loadBagModel.onError = function(task, message, exception) {
			console.log(message, exception);
		};

		if (this.engine) {
			this.engine.resize();
		}

		// We return the fully configured loader
		return loader;
	};

	//Build the scene when the component has been loaded.
	componentDidMount() {
		// Change the displayed bag name
		this.props.onNameChange(this.config['name'], this.config['price'], this.config['product_id'],this.config['variation_id']);

		this.setEngine();
		this.setScene();
		this.setCamera();
		this.loadModels().load();

		// We can add our custom events just like any other DOM event
		window.addEventListener("resize", this.onResizeWindow);
		window.addEventListener("move-camera", this.moveCamera);
		window.addEventListener("move-monogram-camera", this.moveMonogramCamera);
		window.addEventListener("change-texture-one", this.changeTextureOne);
		window.addEventListener("change-texture-two", this.changeTextureTwo);
		window.addEventListener("change-belt-one", this.changeBeltOne);
		window.addEventListener("change-belt-two", this.changeBeltTwo);
		window.addEventListener("change-metal", this.changeMetal);
		window.addEventListener("change-interior", this.changeInterior);
		window.addEventListener("change-text", this.changeText);
		window.addEventListener("change-text-color", this.changeTextColor);
	}
	//Renderes our Canvas tag and saves a reference to it.
	render() {
		return <canvas className="scene" ref={el => (this.stage = el)}></canvas>;
	}
}

//returns the scene to be used by other components
export default MacroPucker;
